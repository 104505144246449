import RidersView from "./Views/Riders/RidersView";
import { Routes, Route } from 'react-router-dom';
import React, { useEffect } from "react";
import Layout from "./Layout/Layout";
import MainLayout from "./Layout/MainLayout";

import ReportsView from "./Views/Reports/ReportsView";
import FleetView from "./Views/Fleet/FleetView";
import MapView from "./Views/Map/MapView";
import TripsView from "./Views/Trips/TripsView";
import CouponsView from "./Views/Coupons/CouponsView";
import PricingView from "./Views/Pricing/PricingView";
import PassesView from "./Views/Passes/PassesView";
import OperatorsView from "./Views/Operators/OperatorsView";
import VehiculeProblemView from "./Views/VehiculeProblem/FleetProblemView";
import SettingsView from "./Views/Settings/SettingsView";
import SupportView from "./Views/Support/SupportView";
import AdvtCampaignView from "./Views/AdvtCampaign/AdvtCampaignView";
import LoginView from "./Views/Login/LoginView";
import PersistLogin from "./Views/Login/Components/PersistLogin";
import ForgetPasswordView from "./Views/Login/Components/ForgetPasswordView";
import ResetPasswordView from "./Views/Login/Components/ResetPasswordView";
import NotFound from "./Views/Login/Components/NotFound";
import RequireAuth  from "./Views/Login/Components/RequireAuth";
import Unauthorized  from "./Views/Login/Components/Unauthorized";
import WelcomeWiew from "./Views/Welcome/WelcomeView";
import HelpCenterView from "./Views/Welcome/components/Sections/FooterPages/HelpCenterView";
import PrivacyPolicyView from "./Views/Welcome/components/Sections/FooterPages/PrivacyPolicyView";
import ImprintView from "./Views/Welcome/components/Sections/FooterPages/ImprintView";
import GeneralTerms from "./Views/Welcome/components/Sections/FooterPages/GeneralTermsView";
import LiveChatView from "./Views/Welcome/LiveChatView";
import TaxiDriverView from "./Views/TaxiDriverInscription/TaxiDriverInscriptionView";

import RegisterTaxiDriverView from "./Views/TaxiDrivers/TaxiDriversView";
import InscriptionTaxiDriver from "./Views/TaxiDriverInscription/Components/InscriptionTaxiDriver";
import TaxiDriverInscriptionView from "./Views/TaxiDriverInscription/TaxiDriverInscriptionView";
import TaxiDriversView from "./Views/TaxiDrivers/TaxiDriversView";

import CitrinTaxiWelcomeView from "./Views/WelcomeCitrinTaxi/CitrinTaxiWelcomeView";

const CreateRoutes = () => {
    
    return (
          <Routes>
            <Route path="/" element={<MainLayout />}>
             {/* public routes */}
             <Route path="welcome"        element={<WelcomeWiew />} /> 
             <Route path="help-center"        element={<HelpCenterView />} /> 
             <Route path="privacy-policy"        element={<PrivacyPolicyView />} /> 
             <Route path="imprint"        element={<ImprintView />} /> 
             <Route path="general-terms"        element={<GeneralTerms />} /> 
             <Route path="liveChat"        element={<LiveChatView />} /> 
             <Route path="register-taxi-driver"   element={<TaxiDriverInscriptionView />} /> 

             <Route path="citrin-taxi-welcome"   element={<CitrinTaxiWelcomeView />} /> 

             <Route path="login"        element={<LoginView />} /> 
             <Route path="reset-password"    element={<ResetPasswordView />} />
             <Route path="forget"       element={<ForgetPasswordView />} />
             <Route path="unauthorized" element={<Unauthorized />} />


              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth allowedRoles={["Reports", "Map", "Fleet", "Riders", "Settings"]} />}>
                  <Route   path="/"                        element={<Layout />}>
                    <Route path="reports"       index      element={<ReportsView />}        />
                    <Route path="map"                      element={<MapView />}            />
                    <Route path="fleet"                    element={<FleetView />}          />
                    <Route path="riders"                   element={<RidersView />}          />
                    <Route path="trips"                    element={<TripsView />}          />
                    <Route path="promos"                   element={<CouponsView />}         />
                    <Route path="campaign"                 element={<AdvtCampaignView />}   />
                    <Route path="pricing"                  element={<PricingView />}        />
                    <Route path="passes"                   element={<PassesView />}  />
                    <Route path="operators"                element={<OperatorsView />}      />
                    <Route path="taxiDrivers"              element={<TaxiDriversView />}      />
                    <Route path="vehiculeProblem"          element={<VehiculeProblemView />}     />
                    <Route path="settings"                 element={<SettingsView />}       />
                    <Route path="Support"                  element={<SupportView />}        />
                  </Route>   
                </Route>                                                                                                     
            </Route>

            {/* catch all */}         
            <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
    );
}
export default CreateRoutes;
