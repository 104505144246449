import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { UserAddOutlined, DownloadOutlined, FundProjectionScreenOutlined } from "@ant-design/icons";
import workFlowDriverImg from "../assets/img/workFlowDriver.png";
import Urls from "../../../Urls";
import { Grid } from "antd";
const { useBreakpoint } = Grid;

export default function DriverAppWorkFollow() {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const isSamllScreen = screens.xs;

  return (
    <Wrapper id="driverAppWorkFollow">
      <div className="whiteBg">
        <div className="container">
          <WorkFollowApp className="flexSpaceCenter">
            {/* Left Section: Text Content */}
            <AddLeft>
              <h3 className="font40">{t('titleDriverAppWorks')}</h3>
              <p className="font20">
                {t('textDriverAppWorks')}
              </p>
              <br />
              {steps.map((step, index) => (
                <StepWrapper key={index}>
                  <NumberCircle>{index + 1}</NumberCircle>
                  <div>
                    <h3 className="font25 semiBold">{t(step.title)}</h3>
                    <p className="font20">{t(step.description)}</p>
                  </div>
                </StepWrapper>
              ))}
            </AddLeft>

            {/* Right Section: Image */}
            { !isSamllScreen ?
            <AddRight>
              <ImgWrapper2 className="flexCenter">
                <img className="radius8" src={workFlowDriverImg} alt="Driver App Workflow" />
              </ImgWrapper2>
            </AddRight>
            :
            <ImageWrapper>
                <img className="radius8" src={workFlowDriverImg} alt="Driver App Workflow" />
            </ImageWrapper>
            }
          </WorkFollowApp>
          <hr/>
          {/* Section: "It’s easy to get started" */}
          <EasyStartWrapper>
            <h3 className="font40 textCenter">{t("titleGetStarted")}</h3>
            <Steps>
              {easyStartSteps.map((step, index) => (
                <StepBox key={index}>
                  <StepIcon>{step.icon}</StepIcon>
                  <h3 className="semiBold">{t(step.title)}</h3>
                  <p className="font20" dangerouslySetInnerHTML={{
                    __html: t(step.description),
                  }} />
                </StepBox>
              ))}
            </Steps>
          </EasyStartWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const steps = [
  {
    title: "titleAccept",
    description: "textAccept",
  },
  {
    title: "titleNegotiate",
    description: "textNegotiate",
  },
  {
    title: "titlePickUp",
    description: "textPickUp",
  },
  {
    title: "titleDriveDestination",
    description: "textDriveDestination",
  },
  {
    title: "titleEarnMoney",
    description: "textEarnMoney",
  },
];


const easyStartSteps = [
  {
    icon: <UserAddOutlined />,
    title: "titleSignUp",
    description: "textSignUp",
  },
  {
    icon: <DownloadOutlined />,
    title: "titleDownloadApp",
    description: `You can find us in the <a href="`+ Urls.googlePlay +`" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">App Store</a> and <a href="`+ Urls.appleStore +`" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">Google Play store</a>.`,
  },
  {
    icon: <FundProjectionScreenOutlined />,
    title: "titleStartEarning",
    description: "textStartEarning",
  },
];

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const WorkFollowApp = styled.div`
  padding: 100px 0;
  margin: 20px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
 @media (max-width: 860px) {
    width: 80%;
  }
`;

const AddRight = styled.div`
  width: 50%;
  position: relative;
   @media (max-width: 860px) {
    width: 80%;
     text-align: center;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;

  h3 {
    margin: 0;
  }

  p {
    margin: 5px 0 0;
  }
`;

const NumberCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #F5D91D;
  color: #0B093B;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
   flex-shrink: 0; /* Prevent shrinking */
`;

const ImgWrapper2 = styled.div`
  width: 120%;
  img {
    width: 100%;
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 20px;
  img {
    max-width: 110%;
    height: auto;
    border-radius: 25px;
  }
`;

const EasyStartWrapper = styled.div`
  margin: 60px auto;
    margin-top: 30px;

  text-align: center;
  max-width: 1500px;

    
    @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center horizontally */
    padding: 0 20px; /* Optional padding for smaller screens */
  }

  @media (max-width: 768px) {
    width: 90%; /* Optional to make it more compact on even smaller screens */
  }
`;

const Steps = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const StepBox = styled.div`
  text-align: center;
  max-width: 250px;

  h3 {
    margin-top: 15px;
    font-size: 25px;
  }
`;

const StepIcon = styled.div`
  font-size: 50px;
  color: #0b093b;
`;
