import React from "react";
import styled from "styled-components";
// Components
// Assets
import { Grid } from 'antd';

import contactSocialImg from "../assets/img/contactSocial.png";
import FacebookImg from "../assets/img/facebook.png";
import InstagramImg from "../assets/img/instagram.png";

import GooglePlayImg from "../assets/img/google-play.png";
import AppStoreImg from "../assets/img/app-store.png";
import { useTranslation } from "react-i18next";
import qrCodeImg from "../assets/img/qrCodeCITRIN.png";
import Urls from "../../../Urls";
const { useBreakpoint } = Grid;

export default function ContactUs() {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const isSamllScreen = screens.xs;
  return (
    <Wrapper>
      <div className="whiteBg">
        <div className="container">
          <Contacting className="flexSpaceCenter">

            <AddLeft>
              <h2 className="font40">{t('titleDedicatedDrivers')}</h2>
              <Text>
                <p className="font20">
                  {t('textDedicatedDrivers1')} <br /> <br />{t('textDedicatedDrivers2')} <br /><br />{t('textDedicatedDrivers3')}
                </p>
              </Text>
              <ButtonContainer>
                <a className="pointer flexNullCenter" href="https://www.facebook.com/people/CITRIN/61556454680367/?mibextid=ZbWKwL" >
                  <img src={FacebookImg} alt="facebook-Citrin" width="50" height="50" />
                </a>
                <a className="pointer flexNullCenter" href="https://www.instagram.com/citrin.mobility/" >
                  <img src={InstagramImg} alt="instagram-Citrin" width="50" height="50" />
                </a>
              </ButtonContainer>
            </AddLeft>

            <AddRight>

              <ImgWrapper className="flexCenter">
                <img className="radius8" src={contactSocialImg} alt="contact Social" />
              </ImgWrapper>

            </AddRight>
          </Contacting>
          </div>
          <div className="lightBg"  id="appDownload">
          {/* New Section: Download the App */}
          <DownloadSection className="flexSpaceCenter">
            <DownloadText>
              <h1 className="font60 extraBold">{t('textDownload')}</h1>
            </DownloadText>

            {/* QR and App Buttons Section */}
            <QrAndAppContainer>
              <QrCodeWrapper>
                <p className="font20">{t('scanQr')}</p>
                <img src={qrCodeImg} alt="QR Code" style={{ width: '170px', height: '170px'}} />
              </QrCodeWrapper>

              {/* vertical lines and or in Circle */}
              <VerticalLineWrapper>
                <div className="line"></div>
                <br />
                <div className="circle">{t('or')}</div>
                <br />
                <div className="line"></div>
              </VerticalLineWrapper>

              <AppButtons>
                <a className="pointer flexNullCenter flexCenter" href={Urls.googlePlay} target="_blank">
                  <img src={GooglePlayImg} alt="google-play-Citrin" style={{ maxWidth: isSamllScreen ? '111%' : '200px' }} />
                </a>
                <a className="pointer flexNullCenter flexCenter" href={Urls.appleStore} target="_blank">
                  <img src={AppStoreImg} alt="app-store-Citrin" style={{ maxWidth: isSamllScreen ? '111%' : '200px' }} />
                </a>
              </AppButtons>
            </QrAndAppContainer>

          </DownloadSection>
          </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Contacting = styled.div`
   padding: 100px 0;
  margin: 50px 0;
  position: relative;
  @media (max-width: 960px) {
    flex-direction: column;
    text-align: center;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  color: #0B093B;
  p {
    max-width: 475px;
  }
    @media (max-width: 860px) {
    width: 90%;
    order: 2;
    text-align: center;

    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }

    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 40%;
  @media (max-width: 860px) {
    width: 90%;
    order: 1;
  }
`;

const Text = styled.div`
  padding: 15px 0 50px 0;
  line-height: 1.6rem;
  @media (max-width: 970px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 275%;
    height: 150%;
  }
   @media (max-width: 600px) {
    padding: 20px 0;
    img {
      max-width: 320px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  
   @media (max-width: 768px) {
    gap: 10px;
    justify-content: center;
  }
`;

const DownloadSection = styled.div`
  padding: 40px 20px;
  flex: 1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const DownloadText = styled.div`
  color: #0B093B;
  text-align: center;
  margin-bottom: 20px;
`;

const QrAndAppContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
   @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const QrCodeWrapper = styled.div`
  text-align: center;
  p {
    margin-bottom: 10px;
  }
`;

const VerticalLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 150px;  // Adjust to the total height of your container
  
  .line {
    width: 1px;
    height: 130%;   // Adjust the height of the lines
    background-color: #000;
  }

  .circle {
    width: 40px;    // Circle size
    height: 40px;
    display: block;
    border-radius: 50%;
    background-color: #000;
    font-size: 1.2em;
    text-align: center;
    line-height: 40px; 
    position: absolute;
    left: 50%;
    top: 50%;      
    transform: translateX(-50%) translateY(-50%); // Horizontal and vertical centering
    color: #fff;     
  }

 @media (max-width: 768px) {
    /* Switch to horizontal layout for smaller screens */
    flex-direction: row;
    width: 50%;
    height: 0.5px;  

    .line {
      width: 100%;  
      height: 0.5px; 
      background-color: #000000;
    }

    .circle {
    width: 40px;    // Circle size
    height: 40px;
    display: block;
    border-radius: 50%;
    background-color: #000;
    font-size: 1.2em;
    text-align: center;
    line-height: 40px; 
    position: absolute;
    left: 50%;
    top: 50%;        
    transform: translateX(-50%) translateY(-50%); 
    color: #fff;   
    }
  }

`;


const AppButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
   @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
`;
