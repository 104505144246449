import { Button, Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { PricingTaxi } from '../../../shared/types';
import { useSortedPricingTaxiQuery } from '../hooks/useSortedPricingTaxiQuery';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_PRICING_TAXI, DeletePricingTaxiRequest, DeletePricingTaxiResponse } from '../../../GraphQL';
import PricingTaxiCreateModal from './PricingTaxiCreateModal';
import { useCurrentPricingTaxi } from '../hooks/useCurrentPricingTaxi';
import PricingTaxiEditModal from './PricingTaxiEditModal';


const PricingTaxiTable = () => {
    const [isCreateModalVisible, setCreateModalVisible] = useState(false);
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { sortedPricingsTaxi: pricingsTaxi, refetch, loading, ...pagination } = useSortedPricingTaxiQuery();
    const [deleteMutation] = useMutation<DeletePricingTaxiResponse, DeletePricingTaxiRequest>(CITRIN_DELETE_PRICING_TAXI);
    const { setCurrentPricing } = useCurrentPricingTaxi();

    const handleRemovePricing = (pricingTaxi: PricingTaxi) => {
        setIsLoading(true);
        deleteMutation({ variables: { id: pricingTaxi._id } })
            .then(() => {
                refetch();
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCreatePricing = () => { setCreateModalVisible(true); };

    const handleEditPricingTaxi = (pricingTaxi: PricingTaxi) => {
        setCurrentPricing(pricingTaxi);
        setEditModalVisible(true);
    };


    const columns = [

        {
            title: 'Service Name',
            dataIndex: 'serviceName',
            key: 'serviceName',
        },
        {
            title: 'Time Slot',
            dataIndex: 'timeSlot',
            key: 'timeSlot', 
            render:  (text, record) => 
                record.timeSlot
                    .slice(0, 2)
                    .map(slot => `${slot.startTime} - ${slot.endTime} - ${slot.percentage}%`)
                    .join('\n')
                    .split('\n') 
                    .map((item, index) => <div key={index}>{item}</div>),
                  
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
        },
        {/** 
            title: 'Area Map',
            dataIndex: 'areaMap',
            key: 'areaMap',
        */},
        {
            title: 'Price Unit',
            dataIndex: 'priceUnit',
            key: 'priceUnit',
        },
        {
            title: "Action",
            key: "action",
            render: (_, pricingTaxi) =>
                <Space size="middle">
                    <a href="#" onClick={() => handleEditPricingTaxi(pricingTaxi)} ><EditOutlined style={{ fontSize: '16px', color: 'green' }} /></a>
                    <PopConfirm
                        title={"Delete the taxi price"}
                        description={"Are you sure to delete this service price?"}
                        onConfirmTask={() => handleRemovePricing(pricingTaxi)}
                        popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
                    />
                </Space>
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Taxi Prices ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );

    return (
        <>

            {isCreateModalVisible && <PricingTaxiCreateModal updateList={refetch} isOpen={isCreateModalVisible} onRequestClose={setCreateModalVisible} />}

            {isEditModalVisible && <PricingTaxiEditModal updateList={refetch} isOpen={isEditModalVisible} onRequestClose={setEditModalVisible} />}

            <Row justify={'end'}>
                <Button type="primary"
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    size={'middle'}
                    onClick={handleCreatePricing}>Add</Button>
            </Row>


            <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Col xs={24}>
                    <Spin spinning={isLoading || loading}>
                        <Table
                            dataSource={pricingsTaxi}
                            columns={columns}
                            pagination={false}
                            rowKey={() => Math.random().toString()}
                            scroll={{ x: 1500, y: 400 }}>
                        </Table>
                    </Spin>
                </Col>
            </Row>
            <Paginator />
        </>
    )
};


export default PricingTaxiTable;