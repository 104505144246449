import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import workFlowPassagerImg from "../assets/img/workFlowPassager.png";
import { Grid } from "antd";

const { useBreakpoint } = Grid;
export default function PassengerAppWorkFollow() {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const isSamllScreen = screens.xs;

  return (
    <Wrapper id="passengerAppWorkFollow">
      <div className="lightBg">
        <div className="container">
          <WorkFollowApp className="flexSpaceCenter">
            {/* Left Section: Text Content */} 
            { !isSamllScreen ? 
              <AddLeft>
                <ImgWrapper2 className="flexCenter">
                  <img className="radius8" src={workFlowPassagerImg} alt="Rider App Workflow" />
                </ImgWrapper2>
              </AddLeft> 
              : 
              <ImageWrapper className="flexCenter">
                <img className="radius8" src={workFlowPassagerImg} alt="Rider App Workflow" />
              </ImageWrapper>
            } 

            {/* Right Section: Image */}
            <AddRight>
                <h3 className="font40">{t('titleBookCitrinTaxi')}</h3>
                <p className="font20">
                  {t('textBookCitrinTaxi')}
                </p>
                <br />
                {steps.map((step, index) => (
                  <StepWrapper key={index}>
                    <NumberCircle>{index + 1}</NumberCircle>
                    <div>
                      <h3 className="font25 semiBold">{t(step.title)}</h3>
                      <p className="font20">{t(step.description)}</p>
                    </div>
                  </StepWrapper>
                ))}
            
            </AddRight>
          </WorkFollowApp>

        </div>
      </div>
    </Wrapper>
  );
}

const steps = [
  {
    title: "titleChooseRoad",
    description: "textChooseRoad",
  },
  {
    title: "titleDriverProposals",
    description: "textDriverProposals",
  },
  {
    title: "titleMeetDriver",
    description: "textMeetDriver",
  },
  {
    title: "titleRateDriver",
    description: "textRateDriver",
  },
];



const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  
`;

const WorkFollowApp = styled.div`
  padding: 100px 0;
  margin: 20px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    text-align: center;
  }
`;

const AddRight = styled.div`
  width: 40%;
  @media (max-width: 860px) {
    width: 80%;
  }
`;


const ImageWrapper = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 20px;
  img {
    max-width: 110%;
    height: auto;
    border-radius: 25px;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
  flex-shrink: 0; /* Prevent shrinking */
   flex-basis: 100%; /* Ensure full width */
`;

const NumberCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #F5D91D;
  color: #0B093B;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  flex-shrink: 0; /* Prevent shrinking */
`;

const ImgWrapper2 = styled.div`
  width: 120%;
  img {
    width: 100%;
    height: auto;
  }
`;

