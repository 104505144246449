import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMapProvider } from "@ubilabs/google-maps-react-hooks";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import SuperClusterAlgorithm from "../Utils/superClusterAlgorithm";
import { polygonOptions } from '../Utils/mapOptions';
import { useMapData } from '../hooks/useMapData';
import { v4 as uuidv4 } from 'uuid';

const MapContainer = () => {
  const [mapData, setMapData] = useMapData();
  const { selectedCity, zone } = mapData;
  const [mapContainer, setMapContainer] = useState(null);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 37.2167, lng: 10.1333 });
  const [drawingManager, setDrawingManager] = useState(null);

  const onLoad = useCallback((map) => {
    setMap(map);
    addZones(map, zone ?? { id: "1", coord: []})
    drawZones(map)
  }, []); 

  useEffect(() => {
    if (selectedCity && map && mapContainer) {
      const { latitude, longitude } = selectedCity;
      map.panTo({ lat: latitude, lng: longitude });
    }
  }, [selectedCity?.latitude, selectedCity?.longitude]);

  const addZones = (map, zone) => {    
    const createPolygon = (zone) => {
        const polygon = new google.maps.Polygon({
            map,
            paths: zone.coord,
            ...polygonOptions
        });

        // Get paths from polygon and set event listeners for each path separately
        polygon.getPaths().forEach((path) => {
            google.maps.event.addListener(path, 'insert_at', update_polygon_closure('insert_at', path, zone.id));
            google.maps.event.addListener(path, 'remove_at', update_polygon_closure('remove_at', path, zone.id));
            google.maps.event.addListener(path, 'set_at', update_polygon_closure('set_at', path, zone.id));
        });

        return polygon;
    };
    
    // Create and add the polygon to the map
    const polygonObject = createPolygon(zone); 
};

  const drawZones = (map) => {
    const newDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      polygonOptions: polygonOptions
    });

    newDrawingManager.setMap(map);

    const create_polygon_closure = (event) => {
      let id = uuidv4();
      let polygon = event.overlay;

      polygon.set("editable", true);
      let paths = polygon.getPath().getArray().map((point) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));
      setMapData((prev) => ({ ...prev, zone: { id, coord: paths } }));

      // Get paths from polygon and set event listeners for each path separately
      polygon.getPaths().forEach(function (path, index) {
        google.maps.event.addListener(path, 'insert_at', update_polygon_closure('insert_at', path, id));
        google.maps.event.addListener(path, 'remove_at', update_polygon_closure('remove_at', path, id));
        google.maps.event.addListener(path, 'set_at', update_polygon_closure('set_at', path, id));
      });
    }
    google.maps.event.addListener(newDrawingManager, "overlaycomplete", create_polygon_closure);

    setDrawingManager(newDrawingManager);
  }

  const update_polygon_closure = (event, path, id) => () => {
    let paths = path.getArray().map((point) => ({
      lat: point.lat(),
      lng: point.lng(),
    }));

    setMapData((prev) => ({ ...prev, zone: { id, coord: paths } }));
  };


  return (
    <div>
      <GoogleMapProvider
        googleMapsAPIKey={`${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=drawing&callback`}
        options={{ zoom: 12, center }}
        mapContainer={mapContainer}
        onLoad={onLoad}
      >
        <div ref={(node) => setMapContainer(node)} style={{ height: "60vh" }} />
      </GoogleMapProvider>
    </div>
  );
};
export default MapContainer;
