import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import PricingTable from './Components/PricingTable';
import { PricingInfoProvider } from './hooks/useCurrentPricing';
import PricingTaxiTable from './Components/PricingTaxiTable';
import { PricingTaxiInfoProvider } from './hooks/useCurrentPricingTaxi';

const PricingView = () => {

    return (
        <>
            <>
                <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Col span={12}><Title level={3}>Mico-Mobility Pricing</Title></Col>
                </Row>

                <div style={{ flex: '1 1 auto' }}>
                    <PricingInfoProvider>
                        <PricingTable />
                    </PricingInfoProvider>
                </div>

                <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Col span={12}><Title level={3}>Taxi Pricing</Title></Col>
                </Row>

                <div style={{ flex: '1 1 auto' }}>
                    <PricingTaxiInfoProvider>
                        <PricingTaxiTable />
                    </PricingTaxiInfoProvider>
                </div>
            </>
        </>
    )
};

export default PricingView;