import { useEffect, useMemo } from "react";
import { PricingTaxi } from "../../../shared/types";
import { chunk } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/pricingOverview.linkstate";
import { useGetPricingsTaxiQuery } from "../../../GraphQL/hooks/useGetPricingsTaxiQuery";

const pageSize = 10;
const getPageCount = (pricingsTaxi: PricingTaxi[]) => Math.ceil(pricingsTaxi?.length / pageSize);
const getCurrentPage = (requestedPage: number, pricingsTaxi: PricingTaxi[]) => {
    const pageCount = getPageCount(pricingsTaxi);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedPricingTaxiQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page } = state;

    const { data: unsortedPricingsTaxi, refetch, loading } = useGetPricingsTaxiQuery();
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedPricingsTaxi);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedPricingsTaxi } = useMemo(() => {
        const totalCount = unsortedPricingsTaxi?.length ?? 0;
        const pageCount = getPageCount(unsortedPricingsTaxi);
        const sortedPricingsTaxi = chunk(unsortedPricingsTaxi, pageSize);

        return { totalCount, pageCount, sortedPricingsTaxi };
    }, [unsortedPricingsTaxi]);

    const currentPage = getCurrentPage(page, unsortedPricingsTaxi);

    return {
        sortedPricingsTaxi: sortedPricingsTaxi?.length > 0 ? sortedPricingsTaxi[currentPage - 1] : [],
        refetch: () => refetch(),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
