import React from "react";
import styled from "styled-components";
import TopNavbar from "../../Nav/TopNavbar";
import Footer from "../Footer"
import { useTranslation } from "react-i18next";


export default function PrivacyPolicyView() {

  const { t } = useTranslation();

  return (
    <>
      <TopNavbar />
      <Wrapper id="generalTerms">
        <Container>
          <Content>
            <div className="whiteBg" style={{ padding: "60px 0" }}>
              <div className="whiteBg">
                <Section className="row flexCenter"><Title className="extraBold font60">{t('titleGeneralTerms')}</Title>
                </Section>
               
                <p style={{textAlign: "center"}}>{t('lastUpdate')}</p>
                <br/>
                <Section>
                  <h2>{t('titleIntroduction')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textIntroduction1')}
                    </p>
                    <p className="font18">
                    {t('textIntroduction2')}
                    </p>
                    <p className="font18">
                    {t('textIntroduction3')}
                    </p>
                  </Text>
                </Section>

                <Section>
                  <h2>
                  {t('titleInformationCITRINContact')}
                  </h2>
                  <Text>
                    <p className="font18">
                     {t('textInformationCITRINContact1')}
                    </p>
                    <p className="font18">
                     {t('textInformationCITRINContact2')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2> {t('titleTerms')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textTerms1')}
                    </p>
                    <p className="font18">
                    {t('textTerms2')}
                    </p>
                    <p className="font18">
                    {t('textTerms3')}
                    </p>
                  </Text>
                </Section>
                <Section>

                  <h2>  {t('titleAgreementCITRIN')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textAgreementCITRIN1')}
                    </p>

                    <p className="font18">
                    {t('textAgreementCITRIN2')}
                    </p>

                    <p className="font18">
                    {t('textAgreementCITRIN3')}
                    </p>
                    <p className="font18">
                    {t('textAgreementCITRIN4')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2>  {t('titleUserAccount')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textUserAccount1')}
                    </p>

                    <p className="font18">
                    {t('textUserAccount2')}
                    </p>

                    <p className="font18">
                    {t('textUserAccount3')}
                    </p>

                    <p className="font18">
                    {t('textUserAccount4')}
                    </p>

                    <p className="font18">
                    {t('textUserAccount5')}
                    </p>
                  </Text>
                </Section>

                <Section>
                  <h2>  {t('titleConsigne')}</h2>
                  <Text>
                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes1") }} />
                    </p>

                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes2") }} />
                    </p>

                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes3") }} />
                    </p>

                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes4") }} />
                    </p>

                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes5") }} />
                    </p>
                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes6") }} />
                    </p>
                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes7") }} />
                    </p>
                    <p className="font18">
                    {t('consignes7.1')}
                    </p>
                    <p className="font18">
                    {t('consignes7.2')}
                    </p>
                    <p className="font18">
                    {t('consignes7.3')}
                    </p>
                    <p className="font18">
                    {t('consignes7.4')}
                    </p>
                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("consignes8") }} />
                    </p>
                    <p className="font18">
                    {t('consignes8.1')}
                    </p>
                    <p className="font18">
                    {t('consignes8.2')}
                    </p>
                  </Text>
                </Section>

                <Section>
                  <h2>  {t('titleStartRide')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textStartRide1')}
                    </p>
                    <p className="font18">
                    {t('textStartRide2')}
                    </p>

                    <p className="font18">
                    {t('textStartRide3')}
                    </p>
                    <p className="font18">
                    {t('textStartRide4')}
                    </p>
                 
                      <br />
                    
                    <p className="font18">
                    {t('textStartRide5')}
                    </p>
                   
                      <br />
                    
                    <p className="font18">
                    {t('textStartRide6')}
                    </p>
                    <p className="font18">
                    {t('textStartRide7')}
                    </p>
                    <p className="font18">
                    {t('textStartRide8')}
                    </p>
                  </Text>
                </Section>

                <Section>
                  <h2>{t('titleEndRide')}</h2>
                  <Text>
                    <p className="font18">
                    {t('TextEndRide1')}
                    </p>
                    <p className="font18">
                    {t('TextEndRide2')}
                    </p>

                    <p className="font18">
                    {t('TextEndRide3')}
                    </p>
                    <p className="font18">
                    {t('TextEndRide4')}
                    </p>

                    <p className="font18">
                    {t('TextEndRide5')}
                    </p>

                    <p className="font18">
                    {t('TextEndRide6')}
                    </p>
                    <p className="font18">
                    {t('TextEndRide7')}
                    </p>
                    <p className="font18">
                    {t('TextEndRide8')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2> {t('titleParkVehciles')}</h2>
                  <Text>
                    <p className="font18">
                      {t('textParkVehciles1')}
                    </p>

                    <p className="font18">
                      {t('textParkVehciles2')}
                    </p>

                    <p className="font18">
                      {t('textParkVehciles3')}
                    </p>

                    <p className="font18">
                      {t('textParkVehciles4')}
                    </p>

                    <p className="font18">
                      {t('textParkVehciles5')}
                    </p>

                    <p className="font18">
                      {t('textParkVehciles6')}
                    </p>

                    <p className="font18">
                      {t('textParkVehciles7')}
                    </p>

                    <p className="font18">
                      {t('textParkVehciles8')}
                    </p>

                    <p className="font18"> 
                      {t('textParkVehciles9')}
                    </p>
                                      
                    <p className="font18"> 
                      {t('textParkVehciles10')}
                    </p>
                                       
                    <p className="font18">
                      {t('textParkVehciles11')}
                    </p>
                                     
                    <p className="font18">
                      {t('textParkVehciles12')}
                    </p>

                    <p className="font18"> 
                      {t('textParkVehciles13')}
                    </p>

                    <br />
                    <p className="font18">
                      {t('textParkVehciles14')}
                    </p>
                    <br />
                    <p className="font18">
                      {t('textParkVehciles15')}
                    </p>

                  </Text>
                </Section>
                <Section>
                  <h2> {t('titlePaymentFee')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textPaymentFee1')}
                    </p>
                   
                      <br />
                    
                    <p className="font18">
                    {t('textPaymentFee2')}
                    </p>
                    
                      <br />
                    
                    <p className="font18">
                    {t('textPaymentFee3')}
                    </p>
                    
                      <br />
                   
                    <p className="font18">
                    {t('textPaymentFee4')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textPaymentFee5')}
                    </p>
                    

                  </Text>
                </Section>
                <Section>
                  <h2>{t('titleAssignment')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textAssignment')}<br />
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2> {t('titlePermissionDrive')}</h2>
                  <Text>
                    <p className="font18"> 
                      {t('textPermissionDrive1')}
                    </p>
                    <p className="font18">
                      {t('textPermissionDrive2')}
                    </p>           
                    <p className="font18">
                      {t('textPermissionDrive3')}
                    </p>                 
                    <p className="font18">
                      {t('textPermissionDrive4')}
                    </p>                
                    <p  className="font18"> 
                      {t('textPermissionDrive5')}
                    </p>
              
  
                  </Text>
                </Section>

                <Section>
                  <h2>{t('titleYourObligations')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textYourObligations1')}
                    </p>
                                       
                    <p className="font18">
                      {t('textYourObligations2')}
                    </p>
                                     
                    <p className="font18">
                      {t('textYourObligations3')}
                    </p>
                    
                    <p className="font18">
                      {t('textYourObligations4')}
                    </p>
                                      
                    <p className="font18">
                      {t('textYourObligations5')}
                    </p>
                                     
                    <p className="font18">
                      {t('textYourObligations6')}
                    </p>
                                      
                    <p className="font18">
                      {t('textYourObligations7')}
                   </p>
                    
                    <p className="font18">
                      {t('textYourObligations8')}
                    </p>
                                       
                    <p className="font18">
                      {t('textYourObligations9')}
                    </p>
                                     
                    <p className="font18">
                      {t('textYourObligations10')}
                    </p>
                                        
                    <p className="font18"> 
                      {t('textYourObligations11')}
                    </p>
                                       
                    <p className="font18">
                      {t('textYourObligations12')}
                    </p>
                   
                    <p className="font18">
                      {t('textYourObligations13')}
                    </p>
                                       
                    <p className="font18">
                    {t('textYourObligations15')}
                    </p>
                                    
                    <p className="font18">
                      {t('textYourObligations16')}
                    </p>
                                       
                    <p className="font18">
                      {t('textYourObligations17')}
                    </p>
                                      
                    <p className="font18">
                    {t('textYourObligations18')}
                    </p>
                
                  </Text>
                </Section>
                <Section>
                  <h2> {t('titleCaseAccidents')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textCaseAccidents1')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textCaseAccidents2')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textCaseAccidents3')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textCaseAccidents4')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textCaseAccidents5')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textCaseAccidents6')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textCaseAccidents7')}
                    </p>


                  </Text>
                </Section>

                <Section>
                  <h2>  {t('titleInsurance')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textInsurance1')}
                    </p>
                    <p className="font18">
                    {t('textInsurance2')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2>{t('titleCITRINLiability')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textCITRINLiability1')}
                    </p>
                   
                      <br />
                    
                    <p>{t('textCITRINLiability2')}</p>
                   
                      <br />
                    
                    <p className="font18">
                    {t('textCITRINLiability3')}
                    </p>
                    
                      <br />
                   
                    <p className="font18">
                    {t('textCITRINLiability4')}
                    </p>
                    
                      <br />
                   
                    <p className="font18">
                    {t('textCITRINLiability5')}
                    </p>
                   
                      <br />
                   
                    <p className="font18">
                    {t('textCITRINLiability6')}
                    </p>

                  </Text>
                </Section>
                <Section>
                  <h2>  {t('titleYourLiability')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textYourLiability1')}
                    </p>
                    
                      <br />
                   
                    <p className="font18">
                    {t('textYourLiability2')}
                    </p>
                  
                      <br />
                   
                    <p className="font18">
                    {t('textYourLiability3')}
                    </p>
                   
                      <br />
                   
                    <p className="font18">
                    {t('textYourLiability4')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2>
                  {t('titleDisablingAccessUserAccounts')}
                  </h2>
                  <Text>
                    <p className="font18">
                    {t('textDisablingAccessUserAccounts1')}
                    </p>
                
                      <br />
                 
                    <p className="font18">
                    {t('textDisablingAccessUserAccounts2')}
                    </p>
                 
                      <br />
                  
                    <p className="font18">
                    {t('textDisablingAccessUserAccounts3')}
                    </p>
                 
                      <br />
                 
                    <p className="font18">
                    {t('textDisablingAccessUserAccounts4')}
                    </p>
            
                      <br />
                  
                    <p className="font18">
                    {t('textDisablingAccessUserAccounts5')}
                    </p>
                
                      <br />
                  
                    <p className="font18">
                    {t('textDisablingAccessUserAccounts6')}
                    </p>
                  
                      <br />
                  
                    <p className="font18">
                    {t('textDisablingAccessUserAccounts7')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2>{t('titleTermUserAgreement')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textTermUserAgreement1')}
                    </p>
                    
                      <br />
                   
                    <p className="font18">
                    {t('textTermUserAgreement2')}
                    </p>
                   
                      <br />
                    
                        <p className="font18">
                    {t('textTermUserAgreement3')}
                    </p>
                 
                      <br />
                
                    <p className="font18">
                    {t('textTermUserAgreement4')}
                    </p>
                      <br />
                 
                    <p className="font18">
                    {t('textTermUserAgreement5')}
                    </p>
                    
                      <br />
                   
                    <p className="font18">
                    {t('textTermUserAgreement6')}
                    </p>
                  
                      <br />
                    
                    <p className="font18">
                    {t('textTermUserAgreement7')}
                    </p>
                      <br />
                
                    <p className="font18">
                    {t('textTermUserAgreement8')}
                    </p>
                  
                  </Text>
                </Section>

                <Section>
                  <h2> {t('titlePersonalData')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textPersonalData1')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    <span dangerouslySetInnerHTML={{ __html: t("textPersonalData2") }} />
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2>{t('titleGoverningLaw')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textGoverningLaw')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2> {t('titleFinalProvisions')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textFinalProvisions1')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textFinalProvisions2')}
                    </p>
                    <p>
                      <br />
                    </p>
                    <p className="font18">
                    {t('textFinalProvisions3')}
                    </p>
                    
                      <br />
                    
                    <p className="font18">
                    {t('textFinalProvisions4')}
                    </p>
                  </Text>
                </Section>
                <Section>
                  <h2>  {t('titleCustomerService')}</h2>
                  <Text>
                    <p className="font18">
                    {t('textCustomerService1')}
                    </p>

                    <br />
                    <p className="font18">STASIA Digital</p>
                    <p className="font18">7070, Ras Jebel, Tunisia</p>
                    <p>
                      <b>FR/EN/AR:</b>  +216 92 496 876
                    </p>
                    <p>
                      <b>Email:</b> <a href="mailto:contact@stasia-digital.com">contact@stasia-digital.com</a> 
                    </p>
                  </Text>
                </Section>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}
const Wrapper = styled.section`
  width: 100%;
`;

const Container = styled.div`
  padding: 60px 0;
  background-color: #fff;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;


const Text = styled.div`
  padding: 15px 0 50px 0;
  line-height: 1.7rem;
  @media (max-width: 9700px) {
    padding: 15px 0 50px 0;
    max-width: 100%;
  }
`;
const Title = styled.h1`
  margin-top: 23px;
`;

