import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

import womanUsingPhone from "../assets/img/womanUsingPhone.jpg";
import bussniss from "../assets/img/business.jpg";
import welcomeImg from "../assets/img/welcomeImg.png";
import Urls from "../../../Urls";

export default function Community() {
  const { t } = useTranslation();

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <SectionsContainer>
        {/* Business Section */}
        <Section>
          <h1 className="font40">{t("business")}</h1>
          <p className="font20">{t("textBusiness")}</p>
          <ActionButton className="animate radius8 font18 ButtonInput semiBold pointer" href="https://www.stasia-digital.com/contact">{t("businessSignup")}</ActionButton>
          <ImageWrapper>
            <img src={bussniss} alt="Business" />
          </ImageWrapper>
        </Section>

        {/* Riders Section */}
        <Section>
          <h1 className="font40">{t("riders")}</h1>
          <p className="font20">{t("textRiders")}</p>
          <ActionButton className="animate radius8 font18 ButtonInput semiBold pointer">
            <Link to="appDownload" spy={true} smooth={true} offset={-120}> 
              {t("downloadApp")}
            </Link>
          </ActionButton>
          <ImageWrapper>
            <img src={womanUsingPhone} alt="Riders" />
          </ImageWrapper>
        </Section>

        {/* Drivers Section */}
        <Section>
          <h1 className="font40">{t("drivers")}</h1>
          <p className="font20">{t("textDrivers")}</p>
          <ActionButton  className="animate radius8 font18 ButtonInput semiBold pointer" href={Urls.registerCitrin}>{t("registerCITRIN")}</ActionButton>
          <ImageWrapper>
            <img src={welcomeImg} alt="Drivers" />
          </ImageWrapper>
        </Section>
      </SectionsContainer>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 340px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;


const SectionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  flex: 1;
  text-align: center;
  padding: 60px;
  border-radius: 8px;
  background: #f8f8f8; /* Subtle gray background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a light shadow for depth */
`;

const ActionButton = styled.a`
  display: inline-block;
  margin-top: 20px;
  background-color: #F5D91D;
  color: black;
  text-decoration: none;
  font-weight: bold;
`;

const ImageWrapper = styled.div`
   display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 20px;
  img {
    max-width: 130%;
    height: auto;
    border-radius: 25px;
  }
`;
