import { gql } from '@apollo/client';

/* Rider */

export const Rider_LocationFragment = gql`
    fragment Rider_LocationFragment on Rider_Location {
        latitude
        longitude
        timestamp
    }
`;

export const Rider_AttachmentFragment = gql`
    fragment Rider_AttachmentFragment on Rider_Attachment {
        uid
        size
        name
        type
        url
    }
`;

export const Payment_MethodFragment = gql`
    fragment Payment_MethodFragment on Payment_Method {
        konnect
        ooredooPayment
        creditCard
    }
`;
export const PrivacyFragment = gql`
    fragment  PrivacyFragment on Privacy {
        emailMarketing
        pushNotifications
        updateFeatures
        serveysRequest
        serviceUpdates
    }
`;

export const IdCardMetaDataFragment = gql`
    fragment  IdCardMetaDataFragment on Rider_IdCardMetaData {
        documentName
        ICAOCode
        dCountryName
        dDescription
        dYear
    }
`;

export const RidePassFragment = gql`
    fragment RidePassFragment on RidePass {
        _id
        logicalId
        name
        description
        priceUnit
        discount
        duration
        initialPrice
        startDate
        endDate
        vehiculeType
        unlockPrice
        minutePrice
    }
`;

export const RiderFragment = gql`
    ${Rider_AttachmentFragment}
    ${PrivacyFragment}
    ${IdCardMetaDataFragment}
    ${Payment_MethodFragment}
    fragment RiderFragment on Rider {
        _id
        logicalId
        fullName
        email
        phoneNumber
        birthday
        gender
        country
        riderPhoto {
            ...Rider_AttachmentFragment
        } 
        isPaymentDefaultMethod
        paymentMethod {
            ...Payment_MethodFragment
        }
        isLogged
        signingMethod
        idProvider
        privacy {
            ...PrivacyFragment
        }
        idCardMetaData {
            ...IdCardMetaDataFragment
        }
        idCardPhoto {
            ...Rider_AttachmentFragment
        } 
        riderSelfie {
            ...Rider_AttachmentFragment
        } 
        identityCheck
    }
`;

/* Pricing */
export const PricingFragment = gql`
    fragment PricingFragment on Pricing {
        _id
        logicalId
        name
        note
        minutePrice
        unlockPrice
        vehiculeType
        level 
    }
`;



/* Vehicule */

export const VehiculeCoordinatesFragment = gql`
    fragment VehiculeCoordinatesFragment on Vehicule_Coordinates {
       longitude
       latitude
    }
`;

export const VehiculeLocationFragment = gql`
    ${VehiculeCoordinatesFragment}
    fragment VehiculeLocationFragment on Vehicule_Location {
        type
        coordinates {
            ...VehiculeCoordinatesFragment
        }
    }
`;

export const VehiculeFragment = gql`
    ${VehiculeLocationFragment}
    ${PricingFragment}
    fragment VehiculeFragment on Vehicule {
        _id
        logicalId
        vehiculeType
        qrcode
        mileage
        status
        batteryLevel
        city
        powerType
        operatedDate
        location {
            ...VehiculeLocationFragment
        }
        operatorId
        pricingId {
            ...PricingFragment
        }
    }
`;


/* Trip */
export const LocationFragment = gql`
    fragment LocationFragment on Trip_Location {
        latitude
        longitude
    }
`;

export const TripAttachmentFragment = gql`
    fragment TripAttachmentFragment on Trip_Attachment {
        uid
        size
        name
        type
        url
    }
`;
 
export const TripFragment = gql`
    ${LocationFragment}
    ${TripAttachmentFragment}
    ${VehiculeFragment}
    ${RiderFragment}
    fragment TripFragment on Trip {
        _id
        logicalId
        startPoint {
            ...LocationFragment
        }
        endPoint {
            ...LocationFragment
        }
        startDate
        endDate
        status
        dropPhoto {
            ...TripAttachmentFragment
        }
        vehiculeId {
            ...VehiculeFragment
        }
        riderId {
            ...RiderFragment
        }
    }
`;


/* Operator */

export const OperatorAddressFragment = gql`
    fragment  OperatorAddressFragment on Operator_Address {
        street
        postalCode
        city
        state
        country
    }
`;
export const OperatorAttachmentFragment = gql`
    fragment OperatorAttachmentFragment on Operator_AttachmentUploaded {
        uid
        size
        name
        type
        url
    }
`;
export const OperatorFragment = gql`
    ${OperatorAttachmentFragment}
    ${OperatorAddressFragment}
    fragment OperatorFragment on Operator {
        _id
        logicalId
        name
        website
        phone
        email
        description
        tva
        type
        address {
            ...OperatorAddressFragment
        }
        logo {
            ...OperatorAttachmentFragment
        }
        settingId {
            _id
            fullname
            email
            sectionRoles
        }
    }
`;

/* Setting */
export const SettingFragment = gql`
   ${OperatorFragment}
    fragment SettingFragment on Setting {
        _id
        logicalId
        fullname
        password
        email
        sectionRoles
        operator{
           ...OperatorFragment
        }
    }
`;

export const TokenResponseFragment = gql`
    fragment TokenResponseFragment on TokenResponse {
        accessToken
        operatorId
    }
`;



/* Pass */
export const PassFragment = gql`
    fragment PassFragment on Pass {
        _id
        logicalId
        name
        description
        priceUnit
        discount
        duration
        initialPrice
        unlockPrice
        minutePrice
        vehiculeType
    }
`;

/* Coupon */
export const CouponFragment = gql`
    fragment CouponFragment on Coupon {
        _id
        logicalId
        title
        description
        amount
        reduction
        code
        startingDate
        expirationDate
        status
        type
    }
`;

/* Campaign */
export const CampaignFragment = gql`
    fragment CampaignFragment on Campaign {
        _id
        logicalId
        title
        description
        startingDate
        endDate
        status
    }
`;

/* Reports */
export const ReportsTripsFragment = gql`
    fragment ReportsTripsFragment on ReportTrip {
        year
        value
        category
    }
`;

export const ReportsCO2Fragment = gql`
    fragment ReportsCO2Fragment on ReportCO2 {
        month
        estimate
    }
`;

export const ReportsCountersFragment = gql`
    fragment ReportsCountersFragment on ReportCounters {
        totalTrips
        totalRiders
        totalMileage
        totalRevenue
}
`;
/* Map */

export const CityMapCoordinatesFragment = gql`
    fragment CityMapCoordinatesFragment on CityMap_Coordinates {
        longitude
        latitude
    }
`;

export const LocationMapFragment = gql`
    ${CityMapCoordinatesFragment}
    fragment LocationMapFragment on CityMap_Location {
        type
        coordinates {
            ...CityMapCoordinatesFragment
        }
    }
`;

export const CoordZoneFragment = gql`
    fragment CoordZoneFragment on Zone_Coord {
        lat
        lng
    }
`;

export const ZonesFragment = gql`
    ${CoordZoneFragment}
    fragment ZonesFragment on Map_Zone {
        id_zone
        name_zone
        coord{
            ...CoordZoneFragment
        }   
    }
`;
export const CityMapFragment = gql`
    ${LocationMapFragment}
    ${ZonesFragment}
    fragment CityMapFragment on CityMap {
        _id
        logicalId
        cityName
        location{
            ...LocationMapFragment
        }
        zones{
            ...ZonesFragment
        }
    }
`;
/* City */
export const CityFragment = gql`
    fragment CityFragment on City {
        _id
        logicalId
        cityName
        latitude
        longitude
    }
`;

/* Support */
export const SupportFragment = gql`
    {SupportAttachmentFragment}
    fragment SupportFragment on Support {
        email
        subject
        description
        file {
            ...SupportAttachmentFragment
        }
    }
`;
export const SupportAttachmentFragment = gql`
    fragment SupportAttachmentFragment on Support_AttachmentUploaded {
        uid
        size
        name
        type
        url
    }
`;

/* VehiculeProblem */

export const problemPhotosAttachmentFragment = gql`
    fragment problemPhotosAttachmentFragment on VehiculeProblem_AttachmentUploaded {
        uid
        size
        name
        type
        url
    }
`;
export const VehiculeProblemFragment = gql`
    ${problemPhotosAttachmentFragment}
    fragment VehiculeProblemFragment on VehiculeProblem {
        _id
        logicalId   
        description
        qrCode
        status
        currentLocation {
            type
            coordinates {
                longitude
                latitude
            }
        }
        problemPhotos {
            ...problemPhotosAttachmentFragment
        }
    }
`;

/* welcome contact */
export const ContactFragment = gql`
    fragment ContactFragment on Contact {
        email
        subject
        firstName
        description
    }
`;


/* Invoice  */

export const InvoiceAttachmentFragment = gql`
    fragment InvoiceAttachmentFragment on InvoiceAttachment {
        uid
        size
        name
        type
        url
    }
`;

export const InvoiceFragment = gql`
    ${InvoiceAttachmentFragment}
    fragment InvoiceFragment on Invoice {
        _id
        logicalId
        amount
        ridingDuration
        startDate
        endDate
        reference
        distance
        vehiculeQrCode
        invoiceAttachment {
            ...InvoiceAttachmentFragment
        }  
    }
`;

//* TaxiDriver */


export const TaxiDriver_AttachmentFragment = gql`
    fragment Rider_AttachmentFragment on Rider_Attachment {
        uid
        size
        name
        type
        url
    }
`;

export const TaxiDriverFragment = gql`
     ${Rider_AttachmentFragment}
     ${Rider_LocationFragment}
    fragment TaxiDriverFragment on TaxiDriver {
        _id
        logicalId
        fullName
        birthday
        password
        phoneNumber
        email
        driverPhoto {
            ...Rider_AttachmentFragment
        }
        referralCode
        marque
        year
        immatriculation
        color
        idCard
        licenseNumber
        bankName
        bankAccountNumber
        documentsPhotos{
            ...Rider_AttachmentFragment
        }
        isDriverOnline
        fcmToken
        totalRides
        rating
        currentLocation {
            ...Rider_LocationFragment
        }
        registerStatus
        accountStatus
        expiredInsuranceDate
        createdDate
    }
`;

/* Pricing Taxi*/



export const CoordAreaFragment = gql`
    fragment CoordAreaFragment on Coord_Area {
        lat
        lng
    }
`;


export const AreasMapFragment = gql`
    ${CoordAreaFragment}
    fragment AreasMapFragment on Area_Map {
        id
        coord {
            ...CoordAreaFragment
        }   
    }
`;

export const TimeSlotFragment = gql`
    fragment TimeSlotFragment on time_Slot {
        startTime
        endTime
        percentage 
    }
`;


export const PricingTaxiFragment = gql`
    ${AreasMapFragment}
    ${TimeSlotFragment}
    fragment PricingTaxiFragment on PricingTaxi {
        _id
        logicalId
        serviceName
        timeSlot {
            ...TimeSlotFragment
        }
        note
        areaMap {
            ...AreasMapFragment
        }
        priceUnit
    }
`;
