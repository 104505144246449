import { gql } from '@apollo/client';
import { Trip, Rider, TripQueryFilter, RiderQueryFilter, VehiculeQueryFilter, Vehicule, Operator, OperatorQueryFilter, Setting, TokenResponse, LoginInput, Pricing, Pass, RefrechResponse, Coupon, CouponQueryFilter, Campaign, OperatorInput, ReportsQueryFilter, ReportsCounters, ReportCO2, ReportTrip, CityMap, City, VehiculeInput, ResetPasswordRequestInput, ResetPasswordInput, SupportInput, VehiculeProblem, ContactInput, Invoice, TaxiDriver, TaxiDriverInput, TaxiDriverQueryFilterMGT, TaxiDriverAndTokenResponse, TaxiDriverUpdateInput, PricingTaxi } from "../shared/types";
import { OperatorFragment, PassFragment, PricingFragment, SettingFragment, TokenResponseFragment, TripFragment, VehiculeFragment, CouponFragment, CampaignFragment, ReportsTripsFragment, ReportsCO2Fragment, ReportsCountersFragment, CityMapFragment, CityFragment, VehiculeProblemFragment, RiderFragment, InvoiceFragment, TaxiDriverFragment, PricingTaxiFragment,  } from './fragments';

export type GetTripsRequest = { request: TripQueryFilter };
export type GetTripsResponse = { CITRIN_MGT_getTrips: Trip[] };
export const CITRIN_GET_TRIPS = gql`
   ${TripFragment}
    query getTrips($request: TripQueryFilter) {
        CITRIN_MGT_getTrips(request: $request) {
            ...TripFragment
        }
    }
`;

export type GetRidersRequest = { request: RiderQueryFilter };
export type GetRidersResponse = { CITRIN_MGT_getRiders: Rider[] };
export const CITRIN_GET_RIDERS = gql`
   ${RiderFragment}
    query getUsers($request: RiderQueryFilter) {
        CITRIN_MGT_getRiders(request: $request) {
            ...RiderFragment
        }
    }
`;


export type UpdateRiderRequest = { request: Rider };
export type UpdateRiderResponse = { CITRIN_MGT_updateRider: Rider };
export const CITRIN_UPDATE_RIDER = gql`
   ${RiderFragment}
   mutation updateUserRider($request: RiderInput) {
    CITRIN_MGT_updateRider(request: $request) {
            ...RiderFragment
        }
    }
`;

export type GetCitiesResponse = { CITRIN_MGT_getCities: City[] };
export const CITRIN_GET_CITIES = gql`
    query getCitiesMap {
        CITRIN_MGT_getCities
    }
`;

export type GetVehiculesRequest = { request: VehiculeQueryFilter };
export type GetVehiculesResponse = { CITRIN_MGT_getVehicules: Vehicule[] };
export const CITRIN_GET_VEHICULES = gql`
   ${VehiculeFragment}
    query getVehicules($request: VehiculeQueryFilter) {
        CITRIN_MGT_getVehicules(request: $request) {
            ...VehiculeFragment
        }
    }
`;
export type CreateVehiculeRequest = { request: VehiculeInput };
export type CreateVehiculeResponse = { CITRIN_MGT_createVehicule: Vehicule };
export const CITRIN_CREATE_VEHICULE = gql`
   ${VehiculeFragment}
   mutation createVehicule($request: VehiculeInput) {
        CITRIN_MGT_createVehicule(request: $request) {
            ...VehiculeFragment
        }
    }
`;

export type UpdateVehiculeRequest = { request: Vehicule };
export type UpdateVehiculeResponse = { CITRIN_MGT_updateVehicule: Vehicule };
export const CITRIN_UPDATE_VEHICULE = gql`
   ${VehiculeFragment}
   mutation updateVehicule($request: VehiculeInput) {
        CITRIN_MGT_updateVehicule(request: $request) {
            ...VehiculeFragment
        }
    }
`;

export type DeleteVehiculeRequest = { id: string };
export type DeleteVehiculeResponse = { CITRIN_MGT_deleteVehicule: string };
export const CITRIN_DELETE_VEHICULE = gql`
    mutation deleteVehicule($id: ID) {
        CITRIN_MGT_deleteVehicule(id: $id)
    }
`;

export type GetOperatorsRequest = { request: OperatorQueryFilter };
export type GetOperatorsResponse = {CITRIN_MGT_getOperators: Operator[] };
export const CITRIN_GET_OPERATORS = gql`
   ${OperatorFragment}
    query getOperators($request: OperatorQueryFilter) {
        CITRIN_MGT_getOperators(request: $request) {
            ...OperatorFragment
        }
    }
`;


export type CreateOperatorRequest = { request: OperatorInput, file: any };
export type CreateOperatorResponse = { CITRIN_MGT_createOperator: Operator };
export const CITRIN_CREATE_OPERATOR = gql`
   ${OperatorFragment}
   mutation createOperator($request: OperatorInput, $file: Upload) {
        CITRIN_MGT_createOperator(request: $request, file: $file) {
            ...OperatorFragment
        }
    }
`;

export type UpdateOperatorRequest = { request: OperatorInput, file: any };
export type UpdateOperatorResponse = { CITRIN_MGT_updateOperator: Operator };
export const CITRIN_UPDATE_OPERATOR = gql`
   ${OperatorFragment}
   mutation updateOperator($request: OperatorInput, $file: Upload) {
        CITRIN_MGT_updateOperator(request: $request, file: $file) {
            ...OperatorFragment
        }
    }
`;

export type DeleteOperatorRequest = { id: string };
export type DeleteOperatorResponse = { CITRIN_MGT_deleteOperator: string };
export const CITRIN_DELETE_OPERATOR = gql`
    mutation deleteOperator($id: ID) {
        CITRIN_MGT_deleteOperator(id: $id)
    }
`;


export type GetSettingsRequest = {};
export type GetSettingsResponse = {CITRIN_MGT_getSettings: Setting[] };
export const CITRIN_GET_SETTINGS = gql`
   ${SettingFragment}
    query getSettings {
        CITRIN_MGT_getSettings {
            ...SettingFragment
        }
    }
`;

export type GetSettingRequest = { id: string };
export type GetSettingResponse = {CITRIN_MGT_getSetting: Setting };
export const CITRIN_GET_SETTING = gql`
   ${SettingFragment}
    query getSetting($id: ID) {
        CITRIN_MGT_getSetting(id: $id) {
            ...SettingFragment
        }
    }
`;

export type CreateSettingRequest = { request: Exclude<Setting,  "_id" | "logicalId"> };
export type CreateSettingResponse = { CITRIN_MGT_createSetting: Setting };
export const CITRIN_CREATE_SETTING = gql`
   ${SettingFragment}
   mutation createSetting($request: SettingInput) {
        CITRIN_MGT_createSetting(request: $request) {
            ...SettingFragment
        }
    }
`;

export type UpdateSettingRequest = { request: Setting };
export type UpdateSettingResponse = { CITRIN_MGT_updateSetting: Setting };
export const CITRIN_UPDATE_SETTING = gql`
   ${SettingFragment}
   mutation updateSetting($request: SettingInput) {
        CITRIN_MGT_updateSetting(request: $request) {
            ...SettingFragment
        }
    }
`;

export type DeleteSettingRequest = { id: string };
export type DeleteSettingResponse = { CITRIN_MGT_deleteSetting: string };
export const CITRIN_DELETE_SETTING = gql`
    mutation deleteSetting($id: ID) {
        CITRIN_MGT_deleteSetting(id: $id)
    }
`;

export type CreateLoginUserRequest = { request: LoginInput };
export type CreateLoginUserResponse = { CITRIN_MGT_loginUser: TokenResponse };
export const CITRIN_LOGIN_USER = gql`
    ${TokenResponseFragment}
    mutation loginUser($request: LoginInput) {
        CITRIN_MGT_loginUser(request: $request){
             ...TokenResponseFragment
        }
    }
`;

export type RefreshTokenResponse = { CITRIN_MGT_refreshToken: RefrechResponse };
export const CITRIN_REFRESH_TOKEN = gql`
    mutation refreshToken($request: String) {
        CITRIN_MGT_refreshToken(request: $request) {
            accessToken
        }
    }
`;

export type LogoutUserResponse = { CITRIN_MGT_logoutUser: String };
export const CITRIN_LOGLOUT_USER = gql`
    mutation logoutUser($request: String) {
        CITRIN_MGT_logoutUser(request: $request)
    }
`;


export type CreateResetPasswordRequestRequest = { request: ResetPasswordRequestInput };
export type CreateResetPasswordRequestResponse = { CITRIN_MGT_resetPasswordRequest: String };
export const CITRIN_RESET_PASSWORDREQUEST = gql`
    mutation resetPasswordRequestRequest($request: ResetPasswordRequestInput) {
        CITRIN_MGT_resetPasswordRequest(request: $request)
    }
`;

export type CreateResetPasswordRequest = { request: ResetPasswordInput };
export type CreateResetPasswordResponse = { CITRIN_MGT_resetPassword: String };
export const CITRIN_RESET_PASSWORD = gql`
    mutation resetPasswordRequest($request: ResetPasswordInput) {
        CITRIN_MGT_resetPassword(request: $request)

    }
`;

export type GetPricingsRequest = {};
export type GetPricingsResponse = {CITRIN_MGT_getPricings: Pricing[] };
export const CITRIN_GET_PRICINGS = gql`
   ${PricingFragment}
    query getPricings {
        CITRIN_MGT_getPricings {
            ...PricingFragment
        }
    }
`;

export type CreatePricingRequest = { request: Exclude<Pricing,  "_id" | "logicalId"> };
export type CreatePricingResponse = { CITRIN_MGT_createPricing: Pricing };
export const CITRIN_CREATE_PRICING = gql`
   ${PricingFragment}
   mutation createPricing($request: PricingInput) {
        CITRIN_MGT_createPricing(request: $request) {
            ...PricingFragment
        }
    }
`;

export type UpdatePricingRequest = { request: Pricing };
export type UpdatePricingResponse = { CITRIN_MGT_updatePricing: Pricing };
export const CITRIN_UPDATE_PRICING = gql`
   ${PricingFragment}
   mutation updatePricing($request: PricingInput) {
        CITRIN_MGT_updatePricing(request: $request) {
            ...PricingFragment
        }
    }
`;

export type DeletePricingRequest = { id: string };
export type DeletePricingResponse = { CITRIN_MGT_deletePricing: string };
export const CITRIN_DELETE_PRICING = gql`
    mutation deletePricing($id: ID) {
        CITRIN_MGT_deletePricing(id: $id)
    }
`;


export type GetPricingsTaxiRequest = {};
export type GetPricingsTaxiResponse = {CITRIN_MGT_getPricingsTaxi: PricingTaxi[] };
export const CITRIN_GET_PRICINGS_TAXI = gql`
   ${PricingTaxiFragment}
    query getPricingsTaxi {
        CITRIN_MGT_getPricingsTaxi {
            ...PricingTaxiFragment
        }
    }
`;

export type CreatePricingTaxiRequest = { request: Exclude<PricingTaxi,  "_id" | "logicalId"> };
export type CreatePricingTaxiResponse = { CITRIN_MGT_createPricingTaxi: PricingTaxi };
export const CITRIN_CREATE_PRICING_TAXI = gql`
   ${PricingTaxiFragment}
   mutation createPricingTaxi($request: PricingTaxiInput) {
        CITRIN_MGT_createPricingTaxi(request: $request) {
            ...PricingTaxiFragment
        }
    }
`;

export type UpdatePricingTaxiRequest = { request: PricingTaxi };
export type UpdatePricingTaxiResponse = { CITRIN_MGT_updatePricingTaxi: PricingTaxi };
export const CITRIN_UPDATE_PRICING_TAXI = gql`
   ${PricingTaxiFragment}
   mutation updatePricingTaxi($request: PricingTaxiInput) {
        CITRIN_MGT_updatePricingTaxi(request: $request) {
            ...PricingTaxiFragment
        }
    }
`;

export type DeletePricingTaxiRequest = { id: string };
export type DeletePricingTaxiResponse = { CITRIN_MGT_deletePricingTaxi: string };
export const CITRIN_DELETE_PRICING_TAXI = gql`
    mutation deletePricingTaxi($id: ID) {
        CITRIN_MGT_deletePricingTaxi(id: $id)
    }
`;


export type GetPassesRequest = {};
export type GetPassesResponse = {CITRIN_MGT_getPasses: Pass[] };
export const CITRIN_GET_PASSES = gql`
   ${PassFragment}
    query getPricings {
        CITRIN_MGT_getPasses {
            ...PassFragment
        }
    }
`;


export type CreatePassRequest = { request: Exclude<Pass,  "_id" | "logicalId"> };
export type CreatePassResponse = { CITRIN_MGT_createPass: Pass };
export const CITRIN_CREATE_PASS = gql`
   ${PassFragment}
   mutation createPricing($request: PassInput) {
        CITRIN_MGT_createPass(request: $request) {
            ...PassFragment
        }
    }
`;

export type UpdatePassRequest = { request: Pass };
export type UpdatePassResponse = { CITRIN_MGT_updatePass: Pass };
export const CITRIN_UPDATE_PASS = gql`
   ${PassFragment}
   mutation updatePass($request: PassInput) {
        CITRIN_MGT_updatePass(request: $request) {
            ...PassFragment
        }
    }
`;

export type DeletePassRequest = { id: string };
export type DeletePassResponse = { CITRIN_MGT_deletePass: string };
export const CITRIN_DELETE_PASS = gql`
    mutation deletePass($id: ID) {
        CITRIN_MGT_deletePass(id: $id)
    }
`;

export type GetCouponsRequest = { request: CouponQueryFilter };
export type GetCouponsResponse = { CITRIN_MGT_getCoupons: Coupon[] };
export const CITRIN_GET_COUPONS = gql`
   ${CouponFragment}
    query getCoupons($request: CouponQueryFilter) {
        CITRIN_MGT_getCoupons(request: $request) {
            ...CouponFragment
        }
    }
`;

export type CreateCouponRequest = { request: Exclude<Coupon,  "_id" | "logicalId" | "createdDate"> };
export type CreateCouponResponse = { CITRIN_MGT_createCoupon: Coupon };
export const CITRIN_CREATE_COUPON = gql`
   ${CouponFragment}
   mutation createCoupon($request: CouponInput) {
        CITRIN_MGT_createCoupon(request: $request) {
            ...CouponFragment
        }
    }
`;

export type DeleteCouponRequest = { id: string };
export type DeleteCouponResponse = { CITRIN_MGT_deleteCoupon: string };
export const CITRIN_DELETE_COUPON = gql`
    mutation deleteCoupon($id: ID) {
        CITRIN_MGT_deleteCoupon(id: $id)
    }
`;

export type GetCampaignsRequest = {};
export type GetCampaignsResponse = {CITRIN_MGT_getCampaigns: Setting[] };
export const CITRIN_GET_CAMPAIGNS = gql`
   ${CampaignFragment}
    query getCampaigns {
        CITRIN_MGT_getCampaigns {
            ...CampaignFragment
        }
    }
`;

export type GetCampaignRequest = { id: string };
export type GetCampaignResponse = {CITRIN_MGT_getCampaign: Setting };
export const CITRIN_GET_CAMPAIGN = gql`
   ${CampaignFragment}
    query getCampaign($id: ID) {
        CITRIN_MGT_getCampaign(id: $id) {
            ...CampaignFragment
        }
    }
`;

export type CreateCampaignRequest = { request: Exclude<Campaign,  "_id" | "logicalId"> };
export type CreateCampaignResponse = { CITRIN_MGT_createCampaign: Setting };
export const CITRIN_CREATE_CAMPAIGN = gql`
   ${CampaignFragment}
   mutation createCampaign($request: CampaignInput) {
        CITRIN_MGT_createCampaign(request: $request) {
            ...CampaignFragment
        }
    }
`;

export type DeleteCampaignRequest = { id: string };
export type DeleteCampaignResponse = { CITRIN_MGT_deleteCampaign: string };
export const CITRIN_DELETE_CAMPAIGN = gql`
    mutation deleteCampaign($id: ID) {
        CITRIN_MGT_deleteCampaign(id: $id)
    }
`;

export type PushNotificationCampaignRequest = { id: string };
export type PushNotificationCampaignResponse = { CITRIN_MGT_sendPushNotificationCampaign: string };
export const CITRIN_SEND_PUSHNOTIFICATION_CAMPAIGN = gql`
    mutation sendPushNotificationCampaign($id: ID) {
        CITRIN_MGT_sendPushNotificationCampaign(id: $id)
    }
`;

export type GetReportsRequest = { request: ReportsQueryFilter };
export type GetReportsCountersResponse = {CITRIN_MGT_getReportsCounters: ReportsCounters };
export const CITRIN_GET_REPORTS_COUNTERS = gql`
   ${ReportsCountersFragment}
    query getReportsCounters($request: ReportsQueryFilter) {
        CITRIN_MGT_getReportsCounters(request: $request) {
            ...ReportsCountersFragment
        }
    }
`;

export type GetReportsCO2Response = {CITRIN_MGT_getReportsCO2: ReportCO2[] };
export const CITRIN_GET_REPORTS_CO2 = gql`
   ${ReportsCO2Fragment}
    query getReportsCO2($request: ReportsQueryFilter) {
        CITRIN_MGT_getReportsCO2(request: $request) {
            ...ReportsCO2Fragment
        }
    }
`;

export type GetReportsTripsResponse = {CITRIN_MGT_getReportsTrips: ReportTrip[] };
export const CITRIN_GET_REPORTS_TRIPS = gql`
   ${ReportsTripsFragment}
    query getReportsTrips($request: ReportsQueryFilter) {
        CITRIN_MGT_getReportsTrips(request: $request) {
            ...ReportsTripsFragment
        }
    }
`;



export type GetAllCitiesMapResponse = {CITRIN_MGT_getAllCitiesMap: CityMap[] };
export const CITRIN_GET_AllCitiesMap = gql`
   ${CityMapFragment}
    query getCityMap {
        CITRIN_MGT_getAllCitiesMap {
            ...CityMapFragment
        }
    }
`;


export type CreateCityMapRequest = { request: Exclude<CityMap,  "_id" | "logicalId"> };
export type CreateCityMapResponse = { CITRIN_MGT_createCityMap: CityMap };
export const CITRIN_CREATE_CityMap = gql`
   ${CityMapFragment}
   mutation createCityMap($request: CityMapInput) {
        CITRIN_MGT_createCityMap(request: $request) {
            ...CityMapFragment
        }
    }
`;

export type UpdateCityMapRequest = { request: CityMap };
export type UpdateCityMapResponse = { CITRIN_MGT_updateCityMap: CityMap };
export const CITRIN_UPDATE_CityMap = gql`
   ${CityMapFragment}
   mutation updateCityMap($request: CityMapInput) {
        CITRIN_MGT_updateCityMap(request: $request) {
            ...CityMapFragment
        }
    }
`;

export type DeleteCityMapRequest = { id: string };
export type DeleteCityMapResponse = { CITRIN_MGT_deleteCityMap: string };
export const CITRIN_DELETE_CityMap = gql`
    mutation deleteCityMap($id: ID) {
        CITRIN_MGT_deleteCityMap(id: $id)
    }
`;


export type GetAllCityRequest = {};
export type GetAllCityResponse = {CITRIN_MGT_getAllCity: City[] };
export const CITRIN_GET_ALLCITIES = gql`
   ${CityFragment}
    query getAllCity {
        CITRIN_MGT_getAllCity {
            ...CityFragment
        }
    }
`;

export type CreateCityRequest = { request: Exclude<City,  "_id" | "logicalId"> };
export type CreateCityResponse = { CITRIN_MGT_createCity: City };
export const CITRIN_CREATE_CITIES = gql`
   ${CityFragment}
   mutation createCity($request: CityInput) {
        CITRIN_MGT_createCity(request: $request) {
            ...CityFragment
        }
    }
`;

export type DeleteCityRequest = { id: string };
export type DeleteCityResponse = { CITRIN_MGT_deleteCity: string };
export const CITRIN_DELETE_CITIES = gql`
    mutation deleteCity($id: ID) {
        CITRIN_MGT_deleteCity(id: $id)
    }
`;

export type CreateSupportRequest = { request: SupportInput, file: any };
export type CreateSupportResponse = { CITRIN_MGT_supportUser: String };
export const CITRIN_CREATE_SUPPORT = gql`
   mutation createSupport($request: SupportInput, $file: Upload) {
    CITRIN_MGT_supportUser(request: $request, file: $file) 
  }
`;


export type GetVehiculeProblemsRequest = {};
export type GetVehiculeProblemsResponse = {CITRIN_MGT_getAllVehiculesProblems: VehiculeProblem[] };
export const CITRIN_GET_VEHICULE_PROBLEMS = gql`
   ${VehiculeProblemFragment}
    query getVehiculeProblem {
        CITRIN_MGT_getAllVehiculesProblems {
            ...VehiculeProblemFragment
        }
    }
`;

export type GetVehiculeProblemRequest = { id: string };
export type GetVehiculeProblemResponse = {CITRIN_MGT_getVehiculeProblem: VehiculeProblem };
export const CITRIN_GET_VEHICULE_PROBLEM = gql`
   ${VehiculeProblemFragment}
    query getVehiculeProblem($id: ID) {
        CITRIN_MGT_getVehiculeProblem(id: $id) {
            ...VehiculeProblemFragment
        }
    }
`;

export type UpdateVehiculeProblemRequest = { request: VehiculeProblem };
export type UpdateVehiculeProblemResponse = { CITRIN_MGT_updateVehiculeProblem: VehiculeProblem };
export const CITRIN_UPDATE_VEHICULE_PROBLEM = gql`
   ${VehiculeProblemFragment}
   mutation updateVehiculeProblem($request: VehiculeProblemInput) {
        CITRIN_MGT_updateVehiculeProblem(request: $request) {
            ...VehiculeProblemFragment
        }
    }
`;

export type DeleteVehiculeProblemRequest = { id: string };
export type DeleteVehiculeProblemResponse = { CITRIN_MGT_deleteVehiculeProblem: string };
export const CITRIN_DELETE_VEHICULE_PROBLEM= gql`
    mutation deleteVehiculeProblem($id: ID) {
        CITRIN_MGT_deleteVehiculeProblem(id: $id)
    }
`;


// welcomePageContact
export type CreateContactRequest = { request: ContactInput };
export type CreateContactResponse = { CITRIN_MGT_contact: String };
export const CITRIN_CREATE_CONTACT = gql`
   mutation createContact($request: ContactInput) {
    CITRIN_MGT_contact(request: $request) 
  }
`;

// invoice

export type GetInvoiceByTripIdRequest = { id: string };
export type GetInvoiceByTripIdResponse = { CITRIN_MGT_getInvoiceByTripId: Invoice };
export const CITRIN_GET_INVOICE_BY_TRIP_ID = gql`
   ${InvoiceFragment}
    query CITRIN_MGT_getInvoiceByTripId($id: ID)  {
        CITRIN_MGT_getInvoiceByTripId(id: $id) {
            ...InvoiceFragment
        }
    }
`;





// register taxi driver
export type SignUpDriverRequest = { request: TaxiDriverInput, files: any[] };
export type SignUpDriverResponse = { CITRIN_APP_signUpDriver: TaxiDriver };
export const CITRIN_SIGNUP_TAXI_DRIVER = gql`
   ${TaxiDriverFragment}
   mutation signUpDriver($request: TaxiDriverInput, $files: Upload) {
    CITRIN_APP_signUpDriver(request: $request, files: $files) {
            ...TaxiDriverFragment
        }
    }
`;



export type GetRegisterTaxiDriverRequest = { request: TaxiDriverQueryFilterMGT };
export type GetRegisterTaxiDriverResponse = {CITRIN_MGT_getAllTaxiDrivers: TaxiDriver[] };
export const CITRIN_GET_REGISTER_TAXI_DRIVER = gql`
   ${TaxiDriverFragment}
    query getAllTaxiDrivers($request: TaxiDriverQueryFilterMGT) {
        CITRIN_MGT_getAllTaxiDrivers(request: $request) {
            ...TaxiDriverFragment
        }
    }
`;




export type UpdateAccountStatusDriverRequest = { request: TaxiDriverUpdateInput };
export type UpdateAccountStatusDriverResponse = {  CITRIN_MGT_updateAccountStatusDriver: TaxiDriver };
export const CITRIN_UPDATE_ACCOUNT_STATUS_DRIVER = gql`
    ${TaxiDriverFragment}
    mutation updateAccountStatusDriver($request: TaxiDriverInput) {
        CITRIN_MGT_updateAccountStatusDriver(request: $request) {
            ...TaxiDriverFragment
        }
    }
`;
