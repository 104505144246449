 const Urls = {
    googlePlay: "https://play.google.com/store/apps/details?id=com.stasiamobility.citrin",
    appleStore: "https://apps.apple.com/us/app/citrin-mobility/id454638411",
    registerCitrin: "https://www.citrin-mobility.com/register-taxi-driver",

    termsOfUse: "https://www.citrin-mobility.com/general-terms",
    privacyPolicy: "https://www.citrin-mobility.com/privacy-policy",
}

export default Urls;

 