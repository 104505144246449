import { Grid, Modal, Button } from 'antd';
import React, { useState } from "react";
import styled from "styled-components";
import Footer from "../Welcome/components/Sections/Footer";
import TopNavbar from '../Welcome/components/Nav/TopNavbar';
import { useTranslation } from "react-i18next";
import Community from './Components/Community';
import ValuesCitrin from './Components/ValuesCitrin';
import DriverAppWorkFollow from './Components/DriverAppWorkFollow';
import ContactUs from './Components/ContactUs';
import PassengerAppWorkFollow from './Components/PassengerAppWorkFollow';
import Faqs from './Components/Faqs';
import womanInTaxiImg from './assets/img/womanInTaxi.png';
import qrCodeCITRIN from './assets/img/qrCodeCITRIN.png';
import { Helmet } from "react-helmet";


const CitrinTaxiWelcomeView = () => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Helmet>
        <title>{t("citrinTaxiService")}</title>
      </Helmet>
      
      <TopNavbar />

      {/* Banner Section */}
      <BannerSection>
        <BackgroundImage />
        <RightSide>
          <Title>
            <h3>{t('slogan')}</h3>
            <HeaderP className="font25 regular">
              {t('textSlogan1')}
              <MarkedText>{t('textSlogan2')}</MarkedText>
            </HeaderP>

            {/* Button download */}
            <ActionButton className="animate radius8 font18 ButtonDwnInput semiBold pointer" onClick={showModal}>
              {t('downloadApp')}
            </ActionButton>
          </Title>
        </RightSide>
      </BannerSection>

      {/* Modal Popup */}
      <Modal
        title={<div style={{ textAlign: 'center' }}>{t('titleModal')}</div>}
        open={isModalVisible}
        onCancel={handleClose}
        footer={null}
        centered
      >
        <CenteredContent>
          <img src={qrCodeCITRIN} alt="qrCode-Citrin" style={{ width: '250px', height: '250px', marginBottom: '15px' }} />
          <p className="font17 semiBold">{t('textModal')}</p>
        </CenteredContent>
      </Modal>

      {/* Other Sections */}
      <Community />
      <ValuesCitrin />
      <DriverAppWorkFollow />
      <PassengerAppWorkFollow />
      <ContactUs />
      <Faqs />
      <Footer />
    </div>
  );
};

// Styled Components
const BannerSection = styled.div`
  position: relative;
  height: 751.1px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9;
    @media (max-width: 960px) {
    flex-direction: column;
    height: auto;
    padding: 50px 0;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${womanInTaxiImg});
  background-size: cover;
  background-position: center;
  z-index: 0;
  @media (max-width: 960px) {
    height: 100%;
  }
`;

const Title = styled.div`
  position: relative;
  color: white;
  text-align: right;
  font-weight: 700;
  font-size: 3.1em;
  line-height: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  z-index: 2;
  padding: 20px;
  max-width: 50%;
  margin-bottom: 10px;
    @media (max-width: 560px) {
    text-align: center;
    max-width: 90%;
    font-size: 2.2em;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 2rem;
   @media (max-width: 960px) {
    text-align: center;
    max-width: 100%;
  }
`;
const MarkedText = styled.span`
  font-weight: regular;
  position: relative;
  display: inline-block;
  z-index: 2;
  padding: 2px 20px;
  color: rgb(0, 0, 0);

  /* Apply the gradient background with #F5D91D as the base color */
  background-image: linear-gradient(
    -90deg,
    rgba(245, 216, 29, 0.75),   /* Light transparent yellow */
    rgba(245, 216, 29, 0.84) 60%, /* More intense yellow */
    rgba(245, 216, 29, 0.92) 70%, /* Very intense yellow */
    rgb(245, 217, 29)           /* Full opacity yellow (#F5D91D) */
  );
  border-radius: 1em 0 1em 0;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 120%;
    background: none;
    z-index: -1;
  }

  @media (max-width: 960px) {
    width: 75%;
    justify-content: center;
    padding: 2px 10px;  /* Reduced padding for smaller screens */
    height: auto; /* Ensure height is auto to avoid excessive space */
  }
`;


const RightSide = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 20px;
  }
`;

const ActionButton = styled.a`
  margin-top: 20px;
  color: white;
  text-decoration: none;
  &:hover {
     color: white;
  }
 @media (max-width: 560px) {
    width: 80%;
  }
`;

const CenteredContent = styled.div`
  text-align: center;
   @media (max-width: 960px) {
    width: 100%;
  }
`;


export default CitrinTaxiWelcomeView;
