import React, { useState } from "react";
import { Input, Form, InputNumber, Modal, Button, TimePicker, Col, Row, Tabs } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { PricingTaxi } from "../../../shared/types";
import { handleFormChange, layoutEdit, stripTypenames, validateMessages } from "../../../shared/Utils";
import { useCreatePricingTaxiMutation } from "../../../GraphQL/hooks/useCreatePricingTaxiMutation";
import MapView from "../Map/MapView";

const PricingTaxiCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);
  const mutation = useCreatePricingTaxiMutation();

  const [areaMapValue, setAreaMapValue] = useState<string | undefined>(undefined)

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        const timeSlots = values.timeSlot.map((slot) => ({
          ...slot,
          startTime: dayjs(slot.startTime).format("HH:mm"),
          endTime: dayjs(slot.endTime).format("HH:mm"),
        }));

        mutation({ ...values, timeSlot: timeSlots  })
          .then(() => {
            updateList();
            onRequestClose(false);
          })
          .finally(() => {
            form.resetFields();
          })
          .catch((err) => console.error(err));
      }
    } catch (error) {
      console.log("Form validation failed");
    }
  };

  const handleMapDataChange = (value: any) => {
    const mapData = stripTypenames(value)
    setAreaMapValue(prevState => JSON.stringify(mapData, null, 2))
    form.setFieldsValue({ areaMap: mapData })
  };

  const tabItems = [
    {
      key: 'pricing-details',
      label: 'Pricing Details',
      children: (
        <Form<PricingTaxi>
        {...layoutEdit}
        form={form}
        autoComplete="off"
        name="pricing-form"
        onFieldsChange={() => setFormError(handleFormChange(form))}
        validateMessages={validateMessages}
      >
        <Form.Item name="serviceName" label="Service Name" rules={[{ required: true }]}>
          <Input style={{ width: "60%" }} placeholder="Enter service name" />
        </Form.Item>

        <Form.Item label="Timeslots:" rules={[{ required: true }]}>
          <Form.List name="timeSlot" initialValue={[{ startTime: null, endTime: null, percentage: null }]} >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[16, 8]} align="middle">
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "startTime"]}
                        label="Start Time"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: "Start time is required" }]}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm" />
                      </Form.Item>
                    </Col>

                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "endTime"]}
                        label="End Time"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: "End time is required" }]}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm" />
                      </Form.Item>
                    </Col>

                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "percentage"]}
                        label="Percentage (%)"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: "Percentage is required" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} max={100} />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <br />
                      {/* Remove button only for additional slots */}
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ fontSize: "18px", color: "red", marginRight: "8px" }}
                        />
                      )}
                      {/* Add button for new slots */}
                      <PlusCircleOutlined
                        onClick={() => add()}
                        style={{ fontSize: "18px", color: "green" }}
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item name="note" label="Note" rules={[{ required: true }]}>
          <Input.TextArea rows={4} placeholder="Add a description" />
        </Form.Item>

        <Form.Item name="areaMap" label="Area Map" rules={[{ required: true }]} shouldUpdate>
          <pre>
            <Input.TextArea
              rows={4}
              placeholder="Add an area coords using the MapMarker tab."
              value={areaMapValue}
            />
          </pre>
        </Form.Item>

        <Form.Item name="priceUnit" label="Price Unit" rules={[{ required: true }]}>
          <InputNumber placeholder="Enter price" style={{ width: "60%" }} />
        </Form.Item>
      </Form>
      )
    },

    {
      key: 'area-mapMarker',
      label: 'Area MapMarker',
      children: (
        <MapView onChange={(value: any) =>  handleMapDataChange(value) }/>
      )
    }
  ]

  return (
    <Modal
      forceRender
      title="Add Price"
      open={isOpen}
      width={850}
      onCancel={() => onRequestClose(false)}
      footer={[
        <Button key="cancel" onClick={() => onRequestClose(false)}>
          Cancel
        </Button>,
        <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
          Create
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="pricing-details" items={tabItems} />
    </Modal>
  );
};

export default PricingTaxiCreateModal;
