import { gql, useQuery } from '@apollo/client';
import { DateRangeValue } from '../../../shared/types';

const gqlQueryFilter = gql`
    query overviewRegisterTaxiDriverState {
        CITRIN_MGT_overviewRegisterTaxiDriverState @client
    }
`;
const typeName = 'CITRIN_MGT_overviewRegisterTaxiDriverState';


export type OverviewState = {
    search?: string;
    selectedAccountStatus?: string[];
    selectedRegisterStatus?: string[];
    selectedDateRange?: DateRangeValue;
    page?: number;
};

const initialState: OverviewState = {
    search: '',
    selectedAccountStatus: [],
    selectedRegisterStatus: [],
    selectedDateRange: {
        fromDate: undefined,
        toDate: undefined,
    },
    page: 1,
};

export const useOverviewLinkState = (): [OverviewState, (newState: OverviewState) => void] => {
    const { data, client } = useQuery(gqlQueryFilter, { fetchPolicy: 'cache-first' });
    const setState = (newState: OverviewState) => {
        client.writeQuery({
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewRegisterTaxiDriverState: {
                    value: newState,
                    __typename: typeName,
                },
            },
        });
    };
    return [data?.CITRIN_MGT_overviewRegisterTaxiDriverState?.value ?? initialState, setState];
};

export default {
    defaults: [
        {
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewRegisterTaxiDriverState: {
                    value: initialState,
                    __typename: 'CITRIN_MGT_overviewRegisterTaxiDriverState',
                },
            },
        },
    ],
};