import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import choice from "../assets/img/choice.png";
import requestTaxi from "../assets/img/requestTaxi.png";
import safety from "../assets/img/safety.png";
import priceDt from "../assets/img/priceDt.png";
import professionalism from "../assets/img/professionalism.png";
import { CheckOutlined } from "@ant-design/icons";

export default function ValuesCitrin() {
  const { t } = useTranslation();

  const values = [
    {
      title: t("choice"),
      texts: [t("textChoice1"), t("textChoice2"), t("textChoice3")],
      image: choice, 
    },
    {
      title: t("priceDecision"),
      texts: [t("textPriceDecision1")],
      image: priceDt, 
    },
    {
      title: t("convenience"),
      texts: [t("textConvenience1"), t("textConvenience2")],
      image: requestTaxi, 
    },
    {
      title: t("safety"),
      texts: [t("textSafety1"), t("textSafety2"), t("textSafety3"),  ],
      image: safety, 
    },
    {
      title: t("professionalism"),
      texts: [t("textProfessionalism1"), t("textProfessionalism2"), t("textProfessionalism3"), ],
      image: professionalism,
    },
  ];

  return (
    <Wrapper id="valuesCitrin">
      <div className="lightBg">
        <div className="container">
          <CenteredTitle className="font40">
            {t("titleValue")}
          </CenteredTitle>
          <CenteredTitle className="font38 semiBold">
            {t("sousTitleValue")}
          </CenteredTitle>

          <ValuesRow>
            {values.map((value, index) => (
              <ValueItem key={index}>
                <ImageWrapper>
                  <img src={value.image} alt={value.title} />
                </ImageWrapper>
                <h2 className="font38 semiBold">{value.title}</h2>
                {value.texts.map((text, i) => (
                  <TextWithCheckmark key={i}>
                    <span className="checkmark"><CheckOutlined /></span>
                    <p className="font18">{text}</p>
                  </TextWithCheckmark>
                ))}
              </ValueItem>
            ))}
          </ValuesRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 60px 0;
`;

const ValuesRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: nowrap;
  width: 100%;
 @media (max-width: 970px) {
    flex-direction: column; /* Stack items in a column */
    align-items: center; /* Center align */
  }
`;

const ValueItem = styled.div`
  text-align: left;
  width: 23%;
  margin: 15px;
  flex-shrink: 0;

  h2 {
    margin-top: 10px;
    font-size: 1.4rem;
    text-align: center;
  }
      @media (max-width: 970px) {
    width: 80%; /* Take more width for better readability */
    text-align: center;
  }

  @media (max-width: 600px) {
    width: 100%; /* Full width on mobile */
    margin: 10px 0;
  }
`;

const TextWithCheckmark = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  text-align: justify; /* Justification du texte */
  margin-top: 5px;

  .checkmark {
    color: #F5D91D;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    color: #555;
    text-align: justify; /* Assurer la justification */
  }
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #f0f0f0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CenteredTitle = styled.h3`
  text-align: center;
  margin-bottom: 20px;
`;
