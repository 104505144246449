import { useMutation } from '@apollo/client';
import { PricingTaxi } from '../../shared/types';
import { CITRIN_CREATE_PRICING_TAXI, CreatePricingTaxiRequest, CreatePricingTaxiResponse } from '..';

type CreateMutationFunction = (request: PricingTaxi) => Promise<PricingTaxi>;
export const useCreatePricingTaxiMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreatePricingTaxiResponse, CreatePricingTaxiRequest>(CITRIN_CREATE_PRICING_TAXI);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createPricingTaxi;
     //   updateGetPricingCache(client, updated, ...(keys(request) as any));
        return created;
    };
};
