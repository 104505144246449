import { Button, Col, Row } from 'antd';
import React, { useEffect } from "react";
import { Select } from 'antd';
import MapContainer from './MapContainer';
import { useMapData } from '../hooks/useMapData';
import { useGetAllCityQuery } from '../../../../GraphQL/hooks/useGetAllCityQuery';
import { DeleteOutlined } from '@ant-design/icons';
import { useCurrentPricingTaxi } from '../../hooks/useCurrentPricingTaxi';


const MapContent = () => {
  const { data: cities, loading: isCitiesLoading } = useGetAllCityQuery();
  const { currentPricing } = useCurrentPricingTaxi();

  const [mapData, setMapData] = useMapData();

  useEffect(() => {
    if(currentPricing?.areaMap) {
      setMapData((prev) => ({...prev, zone: currentPricing.areaMap}))
    }
  }, [currentPricing?.areaMap])

  const handleCityChange = (city) => {
    const selectedCity = cities?.filter((e) => e.cityName === city);
    setMapData({ ...mapData, selectedCity: selectedCity[0] });
  };

  const handleZoneClean = () => {
    setMapData((prev) => ({...prev, zone: { id: "1", coord: []} }));
  };

  return (
    <>
    <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Col span={4}>
          <Select placeholder="Select City.." style={{ width: '100%' }} onChange={handleCityChange} >
            {cities?.map((city) => (
              <Select.Option key={city.cityName} value={city.cityName}>
                {city.cityName}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Button danger type="dashed" icon={<DeleteOutlined />} disabled={!mapData?.zone} onChange={handleZoneClean}>Clear zone</Button>   
        </Col>
    </Row>
    <Row>
    <Col span={24}>
      { !isCitiesLoading && mapData?.selectedCity ? (
        <MapContainer />
      ) : (
        // Render a loading indicator or message when data is not available
        <div>Loading...</div>
      )}
    </Col>
  </Row>
  </>
  )
};

export default MapContent;