import React, { useEffect, useState } from "react";
import { Input, Form, InputNumber, Modal, Button, Slider, Row, Col, TimePicker, Tabs } from 'antd';
import dayjs from 'dayjs';
import { PricingTaxi } from "../../../shared/types";
import { handleFormChange, layoutEdit, stripTypenames, validateMessages } from "../../../shared/Utils";
import { useUpdatePricingTaxiMutation } from "../../../GraphQL/hooks/useUpdatePricingTaxiMutation";
import { useCurrentPricingTaxi } from "../hooks/useCurrentPricingTaxi";
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import MapView from "../Map/MapView";

const PricingTaxiEditModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const { currentPricing } = useCurrentPricingTaxi();
  const [formError, setFormError] = useState(false);
  const mutation = useUpdatePricingTaxiMutation();
  const [areaMapValue, setAreaMapValue] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (currentPricing && currentPricing.timeSlot) {
      const timeSlots = currentPricing.timeSlot.map(slot => ({
        startTime: slot.startTime ? dayjs(slot.startTime, "HH:mm") : null,
        endTime: slot.endTime ? dayjs(slot.endTime, "HH:mm") : null,
        percentage: slot.percentage || 0,
      }));
  
      form.setFieldsValue({
        serviceName: currentPricing.serviceName,
        timeSlot: timeSlots,
        note: currentPricing.note,
        areaMap: stripTypenames(currentPricing?.areaMap),
        priceUnit: currentPricing.priceUnit,
      });

    }
  }, [form, currentPricing]);

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        const timeSlots = values.timeSlot.map(slot => ({
                                                          ...slot,
                                                          startTime: dayjs(slot.startTime, 'HH:mm').format('HH:mm'), 
                                                          endTime: dayjs(slot.endTime, 'HH:mm').format('HH:mm'),
                                                      })
                                              );

        mutation({ _id: currentPricing._id, ...values, timeSlot: timeSlots })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => console.log("The Taxi price was successfuly updated"))
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  const handleMapDataChange = (value: any) => {
    const mapData = stripTypenames(value)
    setAreaMapValue(prevState => JSON.stringify(mapData, null, 2))
    form.setFieldsValue({ areaMap: mapData })
  };

  const [rangeKmValue, setRangekmValue] = useState(["1 km", "5 km"]);

  const handleRangekmChange = (value: [number, number]) => {
    const stringValues = value.map((val) => `${val} km`);
    setRangekmValue(stringValues);
  };

  const tabItems = [
    {
      key: 'pricing-details',
      label: 'Pricing Details',
      children: (
        <Form<PricingTaxi> {...layoutEdit} form={form} autoComplete="off" name="nest-messages" onFieldsChange={() => { setFormError(handleFormChange(form)) }} validateMessages={validateMessages}>
        <Form.Item name="serviceName" label="Service Name" rules={[{ required: true }]}>
          <Input style={{ width: '60%' }} placeholder="Name of the service" />
        </Form.Item>

        {/** <Form.Item name="klmRange" label="Klm Range" rules={[{ required: true }]}>
          <Slider
            range
            min={0}
            max={50}
            defaultValue={[1, 5]}
            onChange={handleRangekmChange}
          />
        </Form.Item>*/}

        <Form.Item label="Timeslots:" rules={[{ required: true }]}>
          <Form.List name="timeSlot">
            {(fields, { add, remove }) => (
              <>
                {fields.length === 0 && add(0)} {/* Ensure at least one default slot */}

                {fields.map(({ key, name, ...restField }, index) => (
                  <Row key={key} gutter={[16, 8]} align="middle">
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "startTime"]}
                        label="Start Time"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: "Start time is required" }]}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm" />
                      </Form.Item>
                    </Col>

                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "endTime"]}
                        label="End Time"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: "End time is required" }]}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm" />
                      </Form.Item>
                    </Col>

                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "percentage"]}
                        label="Percentage (%)"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: "Percentage is required" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} max={100} />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <br />
                      {/* Remove button only for additional slots */}
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ fontSize: "18px", color: "red", marginRight: "8px" }}
                        />
                      )}
                      {/* Add button for new slots */}
                      <PlusCircleOutlined
                        onClick={() => add()}
                        style={{ fontSize: "18px", color: "green" }}
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Form.Item>


        <Form.Item name="note" label="Note" rules={[{ required: true }]}>
          <Input.TextArea placeholder="add some description" />
        </Form.Item>

        
          <Form.Item name="areaMap" label="Area Map" rules={[{ required: true }]} shouldUpdate>
            <pre>
              <Input.TextArea
                rows={4}
                placeholder="Edit the area coords using the MapMarker tab."
                value={areaMapValue}
              />
            </pre>
          </Form.Item>
        
        
        <Form.Item name="priceUnit" label="Price Unit" rules={[{ required: true }]}>
          <InputNumber placeholder="Input the price of this service" style={{ width: '60%' }} />
        </Form.Item>
      </Form>
      )
    },

    {
      key: 'area-mapMarker',
      label: 'Area MapMarker',
      children: (
        <MapView onChange={(value: any) => handleMapDataChange(value)} />
      )
    }
  ]

  return (
    <Modal forceRender title="Edit Price" open={isOpen} width={850} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="edit" type="primary" onClick={handleCommit} disabled={formError}>
        Edit
      </Button>
    ]}>
      <Tabs defaultActiveKey="pricing-details" items={tabItems} />
    </Modal>
  );

};

export default PricingTaxiEditModal;
