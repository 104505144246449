import { useMutation } from '@apollo/client';
import { PricingTaxi } from '../../shared/types';
import { CITRIN_UPDATE_PRICING_TAXI, UpdatePricingTaxiRequest, UpdatePricingTaxiResponse } from '..';

type UpdateMutationFunction = (request: PricingTaxi) => Promise<PricingTaxi>;
export const useUpdatePricingTaxiMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation<UpdatePricingTaxiResponse, UpdatePricingTaxiRequest>(CITRIN_UPDATE_PRICING_TAXI);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_MGT_updatePricingTaxi;
     //   updateGetPricingCache(client, updated, ...(keys(request) as any));
        return updated;
    };
};
