import React, { useState } from "react";
import {
  Input,
  Form,
  Select,
  notification,
  Steps,
  Upload,
  DatePicker,
  Row,
  Col,
  Tooltip,
  Checkbox,
  Typography,
} from "antd";
import { FileImageOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSignUpDriverMutation } from "../../../GraphQL/hooks/useSignUpDriverMutation";
import { AllCarModels, allColorOptions, TaxiDriverInput } from "../../../shared/types";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import Urls from "../../../Urls";

export const InscriptionTaxiDriver = () => {
  const { t } = useTranslation();

  const steps = [
    {
      title: '',
      content: t('titlePersonalInfo'),
      description1: t('textPersonalInfo'),
    },
    {
      title: '',
      content: t('titleLegalDetails'),
      description1: t('textLegalDetails')
    },
    {
      title: '',
      content: t('titleDocuments'),
      description1: t('textLegalDetails1'),
      description2: t('textLegalDetails2'),
    },
    /* {
      title: 'Payment Details',
      content: 'We need your payment details to pay you.',
      description: 'We need your payment details to pay you.',
    },*/
  ];

  const attachmentDocumentsRequested = [
    { label: t('cinCard1'), name: "idCardFront" },
    { label: t('cinCard2'), name: "idCardBack" },
    { label: t('driverLicense'), name: "driverLicence" },
    { label: t('professionalCard'), name: "professionalCard" },
    { label: t('grayCard1'), name: "grayCardFront" },
    { label: t('grayCard2'), name: "grayCardBack" },
    { label: t('insurance'), name: "insurance" },
  ]


  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [driverData, setDriverData] = useState<TaxiDriverInput>();
  const [recaptchaState, setRecaptchaState] = useState(false);

  const { mutation, loading } = useSignUpDriverMutation();

  const [attachments, setAttachments] = useState({
    driverPhoto: null,
    idCardFront: null,
    idCardBack: null,
    driverLicence: null,
    professionalCard: null,
    grayCardFront: null,
    grayCardBack: null,
    insurance: null,
  });

  const updateAttachment = (key, file) => {
    setAttachments((prevAttachments) => ({
      ...prevAttachments,
      [key]: file
    }));
  };


  const handleNext = async () => {
    try {
      const values = await form.validateFields();
      const files = Object.values(attachments)
        .flatMap(attachment => attachment)
        .filter(file => file);

      setDriverData(prev => ({ ...prev, ...values }))
      if (currentStep === steps.length - 1 && recaptchaState) {
        mutation(driverData, files)
          .then((result) => {
            notification.success({
              message: t('popupSuccess'),
              description: t('textSuccess'),
            });
            navigate(`/welcome`);
          })
          .catch((err) => {
            notification.error({
              message: t('popupEror'),
              description: t('textEror'),
            });
            navigate(`/register-taxi-driver`);
          })
          .finally(() => {
            form.resetFields();
            setCurrentStep(0);
            setAttachments(null);
          })

      } else {
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      notification.error({
        message: t('validationError'),
        description: t('textErorForm'),
      });
    }
  };


  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };


  const contentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 16px',
  };

  const formStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
  };

  const dateYear = (current) => {
    const currentYear = new Date().getFullYear();
    return current && current.year() > currentYear;
  };

  const dateBirthYear = (current) => {
    const currentDate = new Date();
    const cutoffDate = new Date(
      currentDate.getFullYear() - 17,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return current && current.isAfter(cutoffDate, "day");
  };
  const startMonth = dayjs().subtract(17, "year").startOf("year");

  const validateFullName = (_, value) => {
    if (!value || /^[a-zA-Z\s]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('textErorFullname1')));
  };

  const validatePhoneNumber = (_, value) => {
    const phoneNumberRegex = /^[0-9]{8}$/; // Example regex for 8-digit numbers
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('textErorPhone1')));
  };

  return (
    <div style={contentStyle}>
      <div style={{ color: 'black', fontWeight: 'semi-bold', fontSize: '25px' }}>
        {steps[currentStep].content}
      </div>
      <div style={{ color: 'grey', marginTop: '16px' }}>
        {steps[currentStep].description1}
      </div>
      <div style={{ color: 'grey', marginTop: '8px', marginBottom: '8px' }}>
        {steps[currentStep].description2}
      </div>
      <Steps current={currentStep} items={steps} responsive={false} style={{ marginBottom: '24px' }} />
      <Form form={form} layout="vertical" style={formStyle}>
        {currentStep === 0 && (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Row gutter={[16, 16]} style={{ width: '100%' }}>
                <Col span={12}>
                  <Form.Item
                    name="fullName"
                    label={t('fullName')}
                    rules={
                      [{ required: true, message: t('textErorFullname2') }, { validator: validateFullName }]
                    }

                  >
                    <Input placeholder={t('placeholderFullname')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="birthday"
                    label={t('birthday')}
                    rules={[{ required: true, message: t('textErorBirthday') }]}
                  >
                    <DatePicker disabledDate={dateBirthYear} defaultPickerValue={startMonth} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label={t('Email')}
                    rules={[{ type: 'email', required: true, message: t('textErorEmail') }]}
                  >
                    <Input placeholder={t('placeholderEmail')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label={t('phoneNumber')}
                    rules={[{ required: true, message: t('textErorPhone2') }, { validator: validatePhoneNumber }]}
                  >
                    <Input placeholder={t('placeholderPhone')} type="tel" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ReferralCode"
                    label={t('referralCode')}
                    rules={[{ required: false }]}
                  >
                    <Input
                      type="tel"
                      placeholder={t('placeholderReferralCode')}
                      suffix={
                        <Tooltip title={t('textReferralCode')} placement="rightTop">
                          <InfoCircleOutlined style={{ color: '#1677ff', fontSize: '20px' }} />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="marque"
                    label={t('vehicleModel')}
                    rules={[{ required: true }]}
                  >
                    <Select placeholder={t('placeholderModelVehicle')} showSearch allowClear>
                      {(AllCarModels ?? []).map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="immatriculation"
                    label={t('licensePlate')}
                    rules={[
                      { required: true, message: t('textErorLicencePlate1') },
                      {
                        pattern: /^[0-9]{3} Tunis [0-9]{4}$/,
                      message: t('textErorLicencePlate2') ,
                      },
                    ]}
                  >
                    <Input
                      placeholder="123 Tunis 4567"
                      onChange={(e) => {
                        let value = e.target.value;
                        // Insert "Tunis" after the first 3 digits if it's not already there
                        if (/^\d{3}(?! Tunis)/.test(value)) {
                          value = value.replace(/^(\d{3})(.*)/, "$1 Tunis$2");
                        }
                        // Ensure there is exactly one space after "Tunis"
                        value = value.replace(/Tunis\s*/, "Tunis ");
                        // Limit to the correct format: 3 digits + "Tunis" + up to 4 digits
                        value = value.replace(/^(\d{3} Tunis \d{0,4}).*/, "$1");
                        form.setFieldsValue({ immatriculation: value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="color"
                    label={t('VehicleColor')}
                    rules={[{ required: true, message: t('textErorColorVehicle') }]}
                  >
                    <Select placeholder={t('placeholderColorVehicle')} showSearch allowClear >
                      {(allColorOptions ?? []).map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="year"
                    label={t('vehicleRegistrationYear')}
                    rules={[{ required: true, message: t('textErorYearVehicle') }]}
                  >
                    <DatePicker picker="year" disabledDate={dateYear} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('profilePhoto')}
                  rules={[{ required: true, message: t('uploadProfilePhoto')  }]}
                    style={{ display: 'inline-block' }}
                    labelCol={{ style: { order: 2, textAlign: 'center' } }}
                  >
                    <Upload
                      accept="image/png, image/jpeg"
                      maxCount={1}
                      beforeUpload={() => false}
                      onChange={({ file }) => updateAttachment('driverPhoto', file)}
                      showUploadList={false}
                      listType="picture-card"
                    >
                      {attachments["driverPhoto"]?.size > 0 ? (
                        <div>
                          <FileImageOutlined />
                          <span style={{ display: 'inline-block', maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {attachments["driverPhoto"].name}
                          </span>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>{t('uploadImage')}</div>
                        </div>
                      )}

                    </Upload>
                  </Form.Item>
                </Col>
                <Form.Item
                  name="terms"
                  valuePropName="checked"
                  rules={[{ required: true, message: t('termsCheckboxEror') }]}
                >
                  <CheckBoxWrapper style={{ display: 'flex', alignItems: 'flex-start', paddingBottom: '20px' }}>
                    <Checkbox  style={{ marginRight: '8px' }} />
                    <span style={{ textAlign: 'justify' }}>
                      {t('termsCheckboxText1')}
                      <Typography.Link color="#003EFF" href={Urls.termsOfUse} target="_blank">
                        {t('Terms of Service')}
                      </Typography.Link>
                      {t('termsCheckboxText2')}
                      <Typography.Link color="#003EFF" href={Urls.privacyPolicy} target="_blank">
                        {t('Privacy Policy')}
                      </Typography.Link>
                      {t('termsCheckboxText3')}
                    </span>
                  </CheckBoxWrapper>
                </Form.Item>
              </Row>
            </div>
          </>
        )}

        {currentStep === 1 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <Form.Item
                  name="idCard"
                  label={t('nationalID')}
                  rules={[
                    { required: true, message: t('textErorNationalID') },
                  ]}
                >
                  <Input
                    placeholder={t('placeholderNationalID')}
                    maxLength={8}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      form.setFieldsValue({ idCard: value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="licenseNumber"
                  label={t('licenseNumber')}
                  rules={[{ required: true, message: t('textErorLicenseNumber') }]}
                >
                  <Input
                    placeholder={t('placeholderLicenseNumber')}
                    maxLength={8}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (/^\d{2}(?!\/)/.test(value)) {
                        value = value.replace(/^(\d{2})/, "$1/");
                      }
                      // Limit the length to exactly 2 digits + '/' + 6 digits
                      if (/^\d{2}\/\d*/.test(value)) {
                        value = value.slice(0, 9); // Max length: 2 digits + '/' + 6 digits
                      }
                      form.setFieldsValue({ licenseNumber: value });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        {currentStep === 2 && (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Row gutter={[16, 16]} style={{ width: '100%' }}>
                {attachmentDocumentsRequested.map((item, index) => (
                  <Col span={8} key={index}>
                    <Form.Item
                      labelCol={{ style: { order: 2, textAlign: 'center' } }}
                      name={item.name}
                      label={item.label}
                      valuePropName={item.name}
                      getValueFromEvent={(event) => event?.file}
                      rules={[{ required: true, message: `${t('textUpload1')} ${item.label.toLowerCase()}${t('textUpload2')}` }]}                  >
                      <Upload
                        accept="image/png, image/jpeg"
                        maxCount={1}
                        beforeUpload={() => false}
                        onChange={({ file }) => updateAttachment(item.name, file)}
                        showUploadList={false}
                        listType="picture-card"
                      >
                        {attachments[item.name]?.size > 0 ? (
                          <div>
                            <FileImageOutlined />
                            <span style={{ display: 'inline-block', maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {attachments[item.name].name}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>{t('uploadImage')}</div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </div>
            <RecaptchaWrapper style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
              <ReCAPTCHA
                onChange={() => setRecaptchaState(true)}
                onExpired={() => setRecaptchaState(false)}
                onErrored={() => setRecaptchaState(false)}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              />
            </RecaptchaWrapper>
          </>
        )}

        <Form.Item >
          <ButtonWrapper>
            <button
              className="animate radius8 NextInput semiBold"
              onClick={handleNext}
              disabled={loading || (currentStep < 2 ? recaptchaState : !recaptchaState)}
              style={(currentStep < 2 ? recaptchaState : !recaptchaState) ? { backgroundColor: 'lightgrey' } : {}}
            >
              {currentStep === steps.length - 1 ? t('btnFinish') : t('btnNext')}
            </button>
          </ButtonWrapper>

          {currentStep > 0 && (
            <ButtonWrapper>
              <button
                className="animate radius8 semiBold PreviousInput"
                onClick={handlePrev}
                disabled={loading}
              >
                {t('btnPrevious')}
              </button>
            </ButtonWrapper>
          )}
        </Form.Item>


      </Form>
    </div>
  );
};

export default InscriptionTaxiDriver;
const RecaptchaWrapper = styled.div`
  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const CheckBoxWrapper = styled.div`
  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px; 

   & > button {
    margin-right: 10px; 
  }

  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

