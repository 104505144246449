import { useState } from "react";
import styled from "styled-components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function FAQSection() {
  const [openIndex, setOpenIndex] = useState(null);
  const { t } = useTranslation();


  const faqs = [
    {
      question: t("faqQuestion1"),
      answers: [
        t("faqAnswer1.1"), 
        t("faqAnswer1.2")
      ],
    },
    {
      question: t("faqQuestion2"),
      answers: [
        `${t("faqAnswer2.1")} <br /><br />
         ${t("faqAnswer2.2")} <br /><br />
         ${t("faqAnswer2.3")} <br /><br />
         ${t("faqAnswer2.4")} <br /><br />
         ${t("faqAnswer2.5")}`
      ],
    },
    {
      question: t("faqQuestion3"),
      answers: [
        `${t("faqAnswer3.1")} <br /><br />
         ${t("faqAnswer3.2")} <br /><br />
         ${t("faqAnswer3.3")} <br /><br />
         ${t("faqAnswer3.4")} <br /><br />
         ${t("faqAnswer3.5")}`
      ],
    },
    { 
      question: t("faqQuestion4"), 
      answers: [
        t("faqAnswer4.1"),
        t("faqAnswer4.2"),
      ], 
    //  htmlElement: Pricing()
    },
    { 
      question: t("faqQuestion5"), 
      answers: [
        t("faqAnswer5.1"), 
        t("faqAnswer5.2"),
      ],
    },
    { 
      question: t("faqQuestion6"), 
      answers: [
        t("faqAnswer6.1"),
      ],
    },
  ];
  


  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Wrapper id="faqs">
      <div className="whiteBg">
        <div className="container">
          <FAQTitle className="font60">FAQs</FAQTitle>
          <FAQGroup>
            {faqs.map((faq, index) => (
              <FAQItem key={index}>
                <FAQButton onClick={() => toggleFAQ(index)}>
                  <FAQQuestion className="font25">{faq.question}</FAQQuestion>
                  {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </FAQButton>
                {openIndex === index && (
                  <FAQAnswer className="font20">
                    {faq.answers.map((answer, i) => (
                      <div key={i}>
                        <p dangerouslySetInnerHTML={{ __html: answer }} className="font18" />
                      </div>
                    ))}
                  { faq?.htmlElement } 
                  </FAQAnswer>
                )}

              </FAQItem>
            ))}
          </FAQGroup>
          <p className="font20">{t("otherQuestions")}</p>
          <br />
          <p className="font20">{t("wishingText")}</p>
          <br />
          <p className="font20">{t("teamText")}</p>
        </div>
      </div>
    </Wrapper>
  );



}

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <PricingContainer>
      <h1>🚖 {t("faqAnswerTable1")}</h1>
      <PricingTable>
        <thead>
          <tr>
            <th>{t("faqAnswerTable2")}</th>
            <th>{t("faqAnswerTable3")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("faqAnswerTable4")}</td>
            <td>{t("faqAnswerTable5")}</td>
          </tr>
          <tr>
            <td>{t("faqAnswerTable6")}</td>
            <td>{t("faqAnswerTable7")}</td>
          </tr>
        </tbody>
      </PricingTable>
      <div align="left">
        <strong>{t("faqAnswerTable8")}</strong> 
        <br/> <span dangerouslySetInnerHTML={{ __html: t("faqAnswerTable9") }} /> <span dangerouslySetInnerHTML={{ __html: t("faqAnswerTable10") }} /> {t("faqAnswerTable11")} {''}
        <strong>{t("faqAnswerTable12")}</strong>{t("faqAnswerTable13")}
      </div>
    </PricingContainer>
  )
}
const Wrapper = styled.section`
  padding: 50px 0;
  
`;

const FAQTitle = styled.h2`
  font-weight: bold;
  text-align: center;
  color: #0b093b;
  margin-bottom: 30px;
`;

const FAQGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const FAQItem = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 25px 0;
  margin-bottom: 25px;

  &:first-child {
    margin-top: 20px;
  }
`;

const FAQButton = styled.button`
  width: 100%;
  text-align: left;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`;

const FAQQuestion = styled.span`
  color: #0b093b;
`;

const FAQAnswer = styled.div`
  padding: 15px;
  line-height: 1.6rem;
  color: #0b093b;
  
  .checkmark {
    color: #0b093b;
    margin-right: 10px;
  }

  p {
    display: inline;
  }
`;

/** css for Pricing** */

const PricingContainer = styled.section`
  max-width: 600px;
  margin: 50px auto;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const PricingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;

  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: #f5d91d;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f5d91d;
  color: black;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
`;