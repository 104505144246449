import React, { useState, useEffect } from "react";
import { MapDataContext } from './hooks/useMapData';
import MapContent from './Components/MapContent';
import { MapData } from './Types/types';
 
const MapView = ({ onChange }) => {
  const [mapData, setMapData] = useState<MapData>();

  useEffect(() => {
    if (mapData?.zone) {
      onChange?.(mapData.zone);
    }
  }, [mapData?.zone]);

  return (
    <div style={{ flex: '1 1 auto' }}>
      <MapDataContext.Provider value={[mapData, setMapData]}>
        <MapContent />
      </MapDataContext.Provider>
    </div>
  );
};

export default MapView;

