import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Switch, Select, Col, DatePicker } from 'antd';
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { TaxiDriver } from "../../../shared/types";
import { useCurrentRegisterTaxiDriver } from "../hooks/useCurrentRegisterTaxiDriver";
import { useUpdateStatusDriverMutation } from "../../../GraphQL/hooks/useUpdateStatusDriverMutation";
import { allRegisterStatusTypes, allAccountStatusTypes } from "../../../shared/types";
import moment from "moment";
import dayjs from "dayjs";

const RegisterTaxiDriverEditModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);
  const [newTaxiDriver, setNewTaxiDriver] = useState<TaxiDriver>();
  const { currentRegisterTaxiDriver } = useCurrentRegisterTaxiDriver();

  const handleSwitchAccountStatusChange = (value: string) => {
    setNewTaxiDriver({ ...newTaxiDriver, accountStatus: value });
  };

  const handleSwitchRegisterStatusChange = (value: string) => {
    setNewTaxiDriver({ ...newTaxiDriver, registerStatus: value });
  };

  const handleSelectExpiredInsuranceDate = (date: any, dateString: any) => {
    setNewTaxiDriver({ ...newTaxiDriver, expiredInsuranceDate: moment(date).format("DD-MM-YYYY") })
  };


  const { mutation, loading: creating } = useUpdateStatusDriverMutation();


  useEffect(() => {
    form.setFieldsValue(currentRegisterTaxiDriver);
  }, [form, currentRegisterTaxiDriver]);

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({
          _id: currentRegisterTaxiDriver._id,
          ...values,

        })
          .then(() => {
            onRequestClose(false);
            updateList();
          })
          .finally(() => console.log("The taxi driver was successfully checked"))
          .catch((err) => console.error(err));
      }
      console.log("values", values)
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  return (
    <Modal
      title="Check Driver status"
      open={isOpen}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="edit" type="primary" onClick={handleCommit} disabled={formError}>
          Edit
        </Button>,
      ]}
    >
      <Form<TaxiDriver>
        {...layoutEdit}
        form={form}
        name="nest-messages"
        onFieldsChange={() => {
          setFormError(handleFormChange(form));
        }}
        onValuesChange={(changedValues, allValues) => setNewTaxiDriver(allValues)}
        validateMessages={validateMessages}>
        <Form.Item
          name="accountStatus"
          label="Account status"
          rules={[{ required: true }]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
        <Select
            placeholder="Select account status"
            value={currentRegisterTaxiDriver?.accountStatus}
            onChange={handleSwitchAccountStatusChange}

          >
            {(allAccountStatusTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="registerStatus"
          label="Register status"
          rules={[{ required: true }]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Select
            placeholder="Select register status"
            value={currentRegisterTaxiDriver?.registerStatus}
            onChange={handleSwitchRegisterStatusChange}

          >
            {(allRegisterStatusTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          //name="expiredInsuranceDate"
          label="Expired insurance date"
          rules={[{ required: true, message: "Please select the insurance expiration date!" }]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <DatePicker format={"YYYY-MM-DD"}
             value={dayjs(moment(Number(currentRegisterTaxiDriver?.expiredInsuranceDate)).format("YYYY-MM-DD"))}
             onChange={handleSelectExpiredInsuranceDate}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegisterTaxiDriverEditModal;
