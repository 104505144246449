import { Col, Grid, Row } from 'antd';
import React from "react";
import InscriptionTaxiDriver from './Components/InscriptionTaxiDriver';
import Footer from "../Welcome/components/Sections/Footer";
import TopNavbar from '../Welcome/components/Nav/TopNavbar';
import { useTranslation } from "react-i18next";  

const { useBreakpoint } = Grid;

const TaxiDriverInscriptionView = () => {
  const screens = useBreakpoint();
  const margin = screens.xs ? '10%' : '3%';

  const inscriptionStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: margin,
    marginBlock: margin,
    flex: 1,
  };

  const containerStyle: React.CSSProperties = {
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh' 
  };
  const titleStyle: React.CSSProperties = {
    textAlign: 'center',
    marginTop: '40px',
  };

  const { t } = useTranslation();

  return (
    <div style={containerStyle}>

      <TopNavbar />
        <div style={titleStyle}>
          <h2> {t('title')}</h2>
        </div>
        <div style={inscriptionStyle}>
          <InscriptionTaxiDriver />
        </div>
      <Footer />
    </div>
  )
};

export default TaxiDriverInscriptionView;

