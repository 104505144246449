import { useMutation } from '@apollo/client';
import { TaxiDriver, TaxiDriverUpdateInput } from '../../shared/types';
import { CITRIN_UPDATE_ACCOUNT_STATUS_DRIVER, UpdateAccountStatusDriverRequest, UpdateAccountStatusDriverResponse } from '../index';

type UpdateMutationFunction = (request: Partial<TaxiDriverUpdateInput>) => Promise<TaxiDriver | undefined>;

export const useUpdateStatusDriverMutation = (): { mutation: UpdateMutationFunction; loading: boolean } => {

    const [mutation, { loading }] = useMutation<UpdateAccountStatusDriverResponse, UpdateAccountStatusDriverRequest>(CITRIN_UPDATE_ACCOUNT_STATUS_DRIVER);

    const promiseMutation: UpdateMutationFunction = async (request: Partial<TaxiDriverUpdateInput>) => {
        const result = await mutation({
             variables: { request } 
            
        });
        return result?.data?.CITRIN_MGT_updateAccountStatusDriver;
    };

    return {
        mutation: promiseMutation,
        loading,
    };
};


